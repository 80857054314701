/* You can add global styles to this file, and also import other style files */
@import "~@intersystems/styles/css/core-styles";
@import "header-styles";
@import "~@intersystems/styles/css/layout-wrapper-mat-dialog-panel";
@import "@intersystems/isc-form/assets/styles/isc-form-overlay-panel.scss";
@import "@intersystems/isc-form/assets/styles/variables.scss";

.isc-form-container {
  height: initial !important;
}

body,
html {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-height: 100%;
}

a {
  color: var(--fr-styles-color-primary);
  cursor: pointer;
}

// Styles for mat-slide-toggles

.mat-slide-toggle.mat-checked {
  font-weight: 900;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #333695;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

// Fix styling issues in paginator
mat-paginator {
  .mat-form-field-wrapper {
    padding: 0;
  }
  .mat-paginator-page-size-select {
    margin: 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0 8px 0;
  }
  .mat-select-arrow-wrapper {
    transform: translateY(10%) !important;
  }
  .mat-paginator-container {
    min-height: 40px;
  }

  @media screen and (max-width: 400px) {
    .mat-paginator-range-actions {
      flex-wrap: wrap;

      .mat-paginator-range-label {
        order: 1 !important;
        margin: 0 4px;
      }
    }
  }

  @media only screen and (max-width: 376px) {
    .mat-paginator-container {
      display: flex;
      flex-direction: row;
    }

    .mat-paginator-range-label {
      margin-left: 4px;
    }

    .mat-paginator-page-size {
      margin-left: 0 auto;
    }

    .mat-paginator-range-actions button:nth-child(2) {
      margin-left: 0 !important;
    }

    .mat-paginator-range-actions button:nth-child(4) {
      margin-left: 50% !important;
    }

    .paginator-jump-container {
      right: 135px !important;
    }
  }
}

.mat-table th.left-justified {
  text-align: center;
}
.paginator-container .paginator-jump-container {
  top: 9px !important;
}

// Fix mobile menu
.mobile-dropdown-container {
  width: 300px;
}

// Fix Mat-tabs pagination; see https://github.com/angular/components/issues/23157
.mat-tab-group {
  max-width: 100%;
}

.form-body {
  max-width: 480px;
}
//Add rules for dialogs
.mat-dialog-container .mat-button-base {
  //min-width: 85px !important;
  min-width: 40px !important;
}
//for checkbox in dialog
.mat-checkbox.mat-accent .mat-checkbox-label {
    white-space: break-spaces;
    font-size: var(--fr-confirmation-dialog-font-size-med) !important;
  }

.form-body-mobile {
  padding: 2px !important;
  margin: -2px !important;
  max-width: 300px !important;
  overflow-y: unset !important;
}

// Fix scrolling issue on mobile forms
.form-body-mobile formly-group .field-container {
  padding: 0 !important;
}

// Fix extra space above buttons in footer of mobile forms
.form-footer-mobile .footer-lh {
  display: none;
}

// Fix extra space in search fields and table headers
.table-header mat-form-field:not(.mat-paginator-page-size-select) {
  margin: 0;
}

// FIX SCROLLING ISSUE ON FR-TABLE
fr-table {
  box-sizing: border-box;
}

// Fix icon positioning in action rows
lib-actions-icons-cell .mat-icon-button .mat-button-wrapper > * {
  margin-bottom: 2px;
}

// Universal global margin
.mt-16px {
  margin-top: 16px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mr-16px {
  margin-right: 16px !important;
}

.text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}

/* Don't know why these class is not in the core-styles. Copied it from isc-form-harness application */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #f1f1f1;

  .close-modal-icon:hover {
    cursor: pointer;
  }
}

/* Hide new repository button on SQL Builder */
#new-analysis-form-newRepository-button,
#dt-form-newRepository-button {
  display: none;
}

//used to wrap long notification messages
.notification-message {
  word-break: break-word; 
}
.bg-info {
  background-color: #d9edf7;
  border: 2px #9dcff6 solid;
  border-radius: 5px;
  padding: 5px 0 10px 0;

  h3,
  p {
    margin-left: 20px;
  }

  ul {
    list-style: none;
    padding-inline-start: 20px;
  }
}
