//blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
//  margin: 0
//}
//
//button {
//  background-color: initial;
//  background-image: none
//}
//
:focus {
  outline: none
}

//fieldset, ol, ul {
//  margin: 0;
//  padding: 0
//}
//
//ol, ul {
//  list-style: none
//}
//
//html {
//  font-family: var(--font-ui);
//  line-height: 1.5
//}
//
//body {
//  text-rendering: optimizeSpeed;
//  font-family: inherit;
//  line-height: inherit;
//  margin: 0;
//  min-height: 100vh
//}

*, :after, :before {
  border: 0 solid var(--color-border, currentColor);
  box-sizing: border-box
}

hr {
  border-top-width: 1px
}
//
img {
  border-style: solid
}

//textarea {
//  resize: vertical
//}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #a1a1aa
}

input::placeholder, textarea::placeholder {
  color: #a1a1aa
}

//[role=button], button {
//  cursor: pointer
//}
//
//table {
//  border-collapse: collapse
//}
//
//h1, h2, h3, h4, h5, h6 {
//  font-size: inherit;
//  font-weight: inherit
//}
//
.sl-link {
  color: inherit;
  text-decoration: inherit
}

button, input, optgroup, select, textarea {
  color: inherit;
  //line-height: inherit;
  //padding: 0
}

//code, kbd, pre, samp {
//  font-family: var(--font-mono)
//}
//
//audio, canvas, embed, iframe, img, object, svg, video {
//  display: block;
//  vertical-align: middle
//}
//
//img, video {
//  height: auto;
//  max-width: 100%
//}
//
button {
  font-family: var(--font-ui)
}
//
//select {
//  -moz-appearance: none;
//  -webkit-appearance: none
//}
//
//select::-ms-expand {
//  display: none
//}
//
//select {
//  font-size: inherit
//}
//
//iframe {
//  border: 0
//}
//
//@media (prefers-reduced-motion: reduce) {
//  * {
//    animation-duration: .01ms !important;
//    animation-iteration-count: 1 !important;
//    scroll-behavior: auto !important;
//    transition-duration: .01ms !important
//  }
//}

.sl-content-center {
  align-content: center
}

.sl-content-start {
  align-content: flex-start
}

.sl-content-end {
  align-content: flex-end
}

.sl-content-between {
  align-content: space-between
}

.sl-content-around {
  align-content: space-around
}

.sl-content-evenly {
  align-content: space-evenly
}

.sl-items-start {
  align-items: flex-start
}

.sl-items-end {
  align-items: flex-end
}

.sl-items-center {
  align-items: center
}

.sl-items-baseline {
  align-items: baseline
}

.sl-items-stretch {
  align-items: stretch
}

.sl-self-auto {
  align-self: auto
}

.sl-self-start {
  align-self: flex-start
}

.sl-self-end {
  align-self: flex-end
}

.sl-self-center {
  align-self: center
}

.sl-self-stretch {
  align-self: stretch
}

.sl-bg-transparent {
  background-color: initial
}

.sl-bg-current {
  background-color: currentColor
}

.sl-bg-lighten-100 {
  background-color: var(--color-lighten-100)
}

.sl-bg-darken-100 {
  background-color: var(--color-darken-100)
}

.sl-bg-primary {
  background-color: var(--color-primary)
}

.sl-bg-primary-tint {
  background-color: var(--color-primary-tint)
}

.sl-bg-primary-light {
  background-color: var(--color-primary-light)
}

.sl-bg-primary-dark {
  background-color: var(--color-primary-dark)
}

.sl-bg-primary-darker {
  background-color: var(--color-primary-darker)
}

.sl-bg-success {
  background-color: var(--color-success)
}

.sl-bg-success-tint {
  background-color: var(--color-success-tint)
}

.sl-bg-success-light {
  background-color: var(--color-success-light)
}

.sl-bg-success-dark {
  background-color: var(--color-success-dark)
}

.sl-bg-success-darker {
  background-color: var(--color-success-darker)
}

.sl-bg-warning {
  background-color: var(--color-warning)
}

.sl-bg-warning-tint {
  background-color: var(--color-warning-tint)
}

.sl-bg-warning-light {
  background-color: var(--color-warning-light)
}

.sl-bg-warning-dark {
  background-color: var(--color-warning-dark)
}

.sl-bg-warning-darker {
  background-color: var(--color-warning-darker)
}

.sl-bg-danger {
  background-color: var(--color-danger)
}

.sl-bg-danger-tint {
  background-color: var(--color-danger-tint)
}

.sl-bg-danger-light {
  background-color: var(--color-danger-light)
}

.sl-bg-danger-dark {
  background-color: var(--color-danger-dark)
}

.sl-bg-danger-darker {
  background-color: var(--color-danger-darker)
}

.sl-bg-code {
  background-color: var(--color-code)
}

.sl-bg-on-code {
  background-color: var(--color-on-code)
}

.sl-bg-on-primary {
  background-color: var(--color-on-primary)
}

.sl-bg-on-success {
  background-color: var(--color-on-success)
}

.sl-bg-on-warning {
  background-color: var(--color-on-warning)
}

.sl-bg-on-danger {
  background-color: var(--color-on-danger)
}

.sl-bg-canvas-50 {
  background-color: var(--color-canvas-50)
}

.sl-bg-canvas-100 {
  background-color: var(--color-canvas-100)
}

.sl-bg-canvas-200 {
  background-color: var(--color-canvas-200)
}

.sl-bg-canvas-300 {
  background-color: var(--color-canvas-300)
}

.sl-bg-canvas-400 {
  background-color: var(--color-canvas-400)
}

.sl-bg-canvas-500 {
  background-color: var(--color-canvas-500)
}

.sl-bg-canvas-pure {
  background-color: var(--color-canvas-pure)
}

.sl-bg-canvas {
  background-color: var(--color-canvas)
}

.sl-bg-canvas-tint {
  background-color: var(--color-canvas-tint)
}

.sl-bg-canvas-dialog {
  background-color: var(--color-canvas-dialog)
}

.sl-bg-body {
  background-color: var(--color-text)
}

.sl-bg-body-muted {
  background-color: var(--color-text-muted)
}

.sl-bg-body-light {
  background-color: var(--color-text-light)
}

.hover\:sl-bg-transparent:hover {
  background-color: initial
}

.hover\:sl-bg-current:hover {
  background-color: currentColor
}

.hover\:sl-bg-lighten-100:hover {
  background-color: var(--color-lighten-100)
}

.hover\:sl-bg-darken-100:hover {
  background-color: var(--color-darken-100)
}

.hover\:sl-bg-primary:hover {
  background-color: var(--color-primary)
}

.hover\:sl-bg-primary-tint:hover {
  background-color: var(--color-primary-tint)
}

.hover\:sl-bg-primary-light:hover {
  background-color: var(--color-primary-light)
}

.hover\:sl-bg-primary-dark:hover {
  background-color: var(--color-primary-dark)
}

.hover\:sl-bg-primary-darker:hover {
  background-color: var(--color-primary-darker)
}

.hover\:sl-bg-success:hover {
  background-color: var(--color-success)
}

.hover\:sl-bg-success-tint:hover {
  background-color: var(--color-success-tint)
}

.hover\:sl-bg-success-light:hover {
  background-color: var(--color-success-light)
}

.hover\:sl-bg-success-dark:hover {
  background-color: var(--color-success-dark)
}

.hover\:sl-bg-success-darker:hover {
  background-color: var(--color-success-darker)
}

.hover\:sl-bg-warning:hover {
  background-color: var(--color-warning)
}

.hover\:sl-bg-warning-tint:hover {
  background-color: var(--color-warning-tint)
}

.hover\:sl-bg-warning-light:hover {
  background-color: var(--color-warning-light)
}

.hover\:sl-bg-warning-dark:hover {
  background-color: var(--color-warning-dark)
}

.hover\:sl-bg-warning-darker:hover {
  background-color: var(--color-warning-darker)
}

.hover\:sl-bg-danger:hover {
  background-color: var(--color-danger)
}

.hover\:sl-bg-danger-tint:hover {
  background-color: var(--color-danger-tint)
}

.hover\:sl-bg-danger-light:hover {
  background-color: var(--color-danger-light)
}

.hover\:sl-bg-danger-dark:hover {
  background-color: var(--color-danger-dark)
}

.hover\:sl-bg-danger-darker:hover {
  background-color: var(--color-danger-darker)
}

.hover\:sl-bg-code:hover {
  background-color: var(--color-code)
}

.hover\:sl-bg-on-code:hover {
  background-color: var(--color-on-code)
}

.hover\:sl-bg-on-primary:hover {
  background-color: var(--color-on-primary)
}

.hover\:sl-bg-on-success:hover {
  background-color: var(--color-on-success)
}

.hover\:sl-bg-on-warning:hover {
  background-color: var(--color-on-warning)
}

.hover\:sl-bg-on-danger:hover {
  background-color: var(--color-on-danger)
}

.hover\:sl-bg-canvas-50:hover {
  background-color: var(--color-canvas-50)
}

.hover\:sl-bg-canvas-100:hover {
  background-color: var(--color-canvas-100)
}

.hover\:sl-bg-canvas-200:hover {
  background-color: var(--color-canvas-200)
}

.hover\:sl-bg-canvas-300:hover {
  background-color: var(--color-canvas-300)
}

.hover\:sl-bg-canvas-400:hover {
  background-color: var(--color-canvas-400)
}

.hover\:sl-bg-canvas-500:hover {
  background-color: var(--color-canvas-500)
}

.hover\:sl-bg-canvas-pure:hover {
  background-color: var(--color-canvas-pure)
}

.hover\:sl-bg-canvas:hover {
  background-color: var(--color-canvas)
}

.hover\:sl-bg-canvas-tint:hover {
  background-color: var(--color-canvas-tint)
}

.hover\:sl-bg-canvas-dialog:hover {
  background-color: var(--color-canvas-dialog)
}

.hover\:sl-bg-body:hover {
  background-color: var(--color-text)
}

.hover\:sl-bg-body-muted:hover {
  background-color: var(--color-text-muted)
}

.hover\:sl-bg-body-light:hover {
  background-color: var(--color-text-light)
}

.focus\:sl-bg-transparent:focus {
  background-color: initial
}

.focus\:sl-bg-current:focus {
  background-color: currentColor
}

.focus\:sl-bg-lighten-100:focus {
  background-color: var(--color-lighten-100)
}

.focus\:sl-bg-darken-100:focus {
  background-color: var(--color-darken-100)
}

.focus\:sl-bg-primary:focus {
  background-color: var(--color-primary)
}

.focus\:sl-bg-primary-tint:focus {
  background-color: var(--color-primary-tint)
}

.focus\:sl-bg-primary-light:focus {
  background-color: var(--color-primary-light)
}

.focus\:sl-bg-primary-dark:focus {
  background-color: var(--color-primary-dark)
}

.focus\:sl-bg-primary-darker:focus {
  background-color: var(--color-primary-darker)
}

.focus\:sl-bg-success:focus {
  background-color: var(--color-success)
}

.focus\:sl-bg-success-tint:focus {
  background-color: var(--color-success-tint)
}

.focus\:sl-bg-success-light:focus {
  background-color: var(--color-success-light)
}

.focus\:sl-bg-success-dark:focus {
  background-color: var(--color-success-dark)
}

.focus\:sl-bg-success-darker:focus {
  background-color: var(--color-success-darker)
}

.focus\:sl-bg-warning:focus {
  background-color: var(--color-warning)
}

.focus\:sl-bg-warning-tint:focus {
  background-color: var(--color-warning-tint)
}

.focus\:sl-bg-warning-light:focus {
  background-color: var(--color-warning-light)
}

.focus\:sl-bg-warning-dark:focus {
  background-color: var(--color-warning-dark)
}

.focus\:sl-bg-warning-darker:focus {
  background-color: var(--color-warning-darker)
}

.focus\:sl-bg-danger:focus {
  background-color: var(--color-danger)
}

.focus\:sl-bg-danger-tint:focus {
  background-color: var(--color-danger-tint)
}

.focus\:sl-bg-danger-light:focus {
  background-color: var(--color-danger-light)
}

.focus\:sl-bg-danger-dark:focus {
  background-color: var(--color-danger-dark)
}

.focus\:sl-bg-danger-darker:focus {
  background-color: var(--color-danger-darker)
}

.focus\:sl-bg-code:focus {
  background-color: var(--color-code)
}

.focus\:sl-bg-on-code:focus {
  background-color: var(--color-on-code)
}

.focus\:sl-bg-on-primary:focus {
  background-color: var(--color-on-primary)
}

.focus\:sl-bg-on-success:focus {
  background-color: var(--color-on-success)
}

.focus\:sl-bg-on-warning:focus {
  background-color: var(--color-on-warning)
}

.focus\:sl-bg-on-danger:focus {
  background-color: var(--color-on-danger)
}

.focus\:sl-bg-canvas-50:focus {
  background-color: var(--color-canvas-50)
}

.focus\:sl-bg-canvas-100:focus {
  background-color: var(--color-canvas-100)
}

.focus\:sl-bg-canvas-200:focus {
  background-color: var(--color-canvas-200)
}

.focus\:sl-bg-canvas-300:focus {
  background-color: var(--color-canvas-300)
}

.focus\:sl-bg-canvas-400:focus {
  background-color: var(--color-canvas-400)
}

.focus\:sl-bg-canvas-500:focus {
  background-color: var(--color-canvas-500)
}

.focus\:sl-bg-canvas-pure:focus {
  background-color: var(--color-canvas-pure)
}

.focus\:sl-bg-canvas:focus {
  background-color: var(--color-canvas)
}

.focus\:sl-bg-canvas-tint:focus {
  background-color: var(--color-canvas-tint)
}

.focus\:sl-bg-canvas-dialog:focus {
  background-color: var(--color-canvas-dialog)
}

.focus\:sl-bg-body:focus {
  background-color: var(--color-text)
}

.focus\:sl-bg-body-muted:focus {
  background-color: var(--color-text-muted)
}

.focus\:sl-bg-body-light:focus {
  background-color: var(--color-text-light)
}

.active\:sl-bg-transparent:active {
  background-color: initial
}

.active\:sl-bg-current:active {
  background-color: currentColor
}

.active\:sl-bg-lighten-100:active {
  background-color: var(--color-lighten-100)
}

.active\:sl-bg-darken-100:active {
  background-color: var(--color-darken-100)
}

.active\:sl-bg-primary:active {
  background-color: var(--color-primary)
}

.active\:sl-bg-primary-tint:active {
  background-color: var(--color-primary-tint)
}

.active\:sl-bg-primary-light:active {
  background-color: var(--color-primary-light)
}

.active\:sl-bg-primary-dark:active {
  background-color: var(--color-primary-dark)
}

.active\:sl-bg-primary-darker:active {
  background-color: var(--color-primary-darker)
}

.active\:sl-bg-success:active {
  background-color: var(--color-success)
}

.active\:sl-bg-success-tint:active {
  background-color: var(--color-success-tint)
}

.active\:sl-bg-success-light:active {
  background-color: var(--color-success-light)
}

.active\:sl-bg-success-dark:active {
  background-color: var(--color-success-dark)
}

.active\:sl-bg-success-darker:active {
  background-color: var(--color-success-darker)
}

.active\:sl-bg-warning:active {
  background-color: var(--color-warning)
}

.active\:sl-bg-warning-tint:active {
  background-color: var(--color-warning-tint)
}

.active\:sl-bg-warning-light:active {
  background-color: var(--color-warning-light)
}

.active\:sl-bg-warning-dark:active {
  background-color: var(--color-warning-dark)
}

.active\:sl-bg-warning-darker:active {
  background-color: var(--color-warning-darker)
}

.active\:sl-bg-danger:active {
  background-color: var(--color-danger)
}

.active\:sl-bg-danger-tint:active {
  background-color: var(--color-danger-tint)
}

.active\:sl-bg-danger-light:active {
  background-color: var(--color-danger-light)
}

.active\:sl-bg-danger-dark:active {
  background-color: var(--color-danger-dark)
}

.active\:sl-bg-danger-darker:active {
  background-color: var(--color-danger-darker)
}

.active\:sl-bg-code:active {
  background-color: var(--color-code)
}

.active\:sl-bg-on-code:active {
  background-color: var(--color-on-code)
}

.active\:sl-bg-on-primary:active {
  background-color: var(--color-on-primary)
}

.active\:sl-bg-on-success:active {
  background-color: var(--color-on-success)
}

.active\:sl-bg-on-warning:active {
  background-color: var(--color-on-warning)
}

.active\:sl-bg-on-danger:active {
  background-color: var(--color-on-danger)
}

.active\:sl-bg-canvas-50:active {
  background-color: var(--color-canvas-50)
}

.active\:sl-bg-canvas-100:active {
  background-color: var(--color-canvas-100)
}

.active\:sl-bg-canvas-200:active {
  background-color: var(--color-canvas-200)
}

.active\:sl-bg-canvas-300:active {
  background-color: var(--color-canvas-300)
}

.active\:sl-bg-canvas-400:active {
  background-color: var(--color-canvas-400)
}

.active\:sl-bg-canvas-500:active {
  background-color: var(--color-canvas-500)
}

.active\:sl-bg-canvas-pure:active {
  background-color: var(--color-canvas-pure)
}

.active\:sl-bg-canvas:active {
  background-color: var(--color-canvas)
}

.active\:sl-bg-canvas-tint:active {
  background-color: var(--color-canvas-tint)
}

.active\:sl-bg-canvas-dialog:active {
  background-color: var(--color-canvas-dialog)
}

.active\:sl-bg-body:active {
  background-color: var(--color-text)
}

.active\:sl-bg-body-muted:active {
  background-color: var(--color-text-muted)
}

.active\:sl-bg-body-light:active {
  background-color: var(--color-text-light)
}

.disabled\:sl-bg-transparent:disabled {
  background-color: initial
}

.disabled\:sl-bg-current:disabled {
  background-color: currentColor
}

.disabled\:sl-bg-lighten-100:disabled {
  background-color: var(--color-lighten-100)
}

.disabled\:sl-bg-darken-100:disabled {
  background-color: var(--color-darken-100)
}

.disabled\:sl-bg-primary:disabled {
  background-color: var(--color-primary)
}

.disabled\:sl-bg-primary-tint:disabled {
  background-color: var(--color-primary-tint)
}

.disabled\:sl-bg-primary-light:disabled {
  background-color: var(--color-primary-light)
}

.disabled\:sl-bg-primary-dark:disabled {
  background-color: var(--color-primary-dark)
}

.disabled\:sl-bg-primary-darker:disabled {
  background-color: var(--color-primary-darker)
}

.disabled\:sl-bg-success:disabled {
  background-color: var(--color-success)
}

.disabled\:sl-bg-success-tint:disabled {
  background-color: var(--color-success-tint)
}

.disabled\:sl-bg-success-light:disabled {
  background-color: var(--color-success-light)
}

.disabled\:sl-bg-success-dark:disabled {
  background-color: var(--color-success-dark)
}

.disabled\:sl-bg-success-darker:disabled {
  background-color: var(--color-success-darker)
}

.disabled\:sl-bg-warning:disabled {
  background-color: var(--color-warning)
}

.disabled\:sl-bg-warning-tint:disabled {
  background-color: var(--color-warning-tint)
}

.disabled\:sl-bg-warning-light:disabled {
  background-color: var(--color-warning-light)
}

.disabled\:sl-bg-warning-dark:disabled {
  background-color: var(--color-warning-dark)
}

.disabled\:sl-bg-warning-darker:disabled {
  background-color: var(--color-warning-darker)
}

.disabled\:sl-bg-danger:disabled {
  background-color: var(--color-danger)
}

.disabled\:sl-bg-danger-tint:disabled {
  background-color: var(--color-danger-tint)
}

.disabled\:sl-bg-danger-light:disabled {
  background-color: var(--color-danger-light)
}

.disabled\:sl-bg-danger-dark:disabled {
  background-color: var(--color-danger-dark)
}

.disabled\:sl-bg-danger-darker:disabled {
  background-color: var(--color-danger-darker)
}

.disabled\:sl-bg-code:disabled {
  background-color: var(--color-code)
}

.disabled\:sl-bg-on-code:disabled {
  background-color: var(--color-on-code)
}

.disabled\:sl-bg-on-primary:disabled {
  background-color: var(--color-on-primary)
}

.disabled\:sl-bg-on-success:disabled {
  background-color: var(--color-on-success)
}

.disabled\:sl-bg-on-warning:disabled {
  background-color: var(--color-on-warning)
}

.disabled\:sl-bg-on-danger:disabled {
  background-color: var(--color-on-danger)
}

.disabled\:sl-bg-canvas-50:disabled {
  background-color: var(--color-canvas-50)
}

.disabled\:sl-bg-canvas-100:disabled {
  background-color: var(--color-canvas-100)
}

.disabled\:sl-bg-canvas-200:disabled {
  background-color: var(--color-canvas-200)
}

.disabled\:sl-bg-canvas-300:disabled {
  background-color: var(--color-canvas-300)
}

.disabled\:sl-bg-canvas-400:disabled {
  background-color: var(--color-canvas-400)
}

.disabled\:sl-bg-canvas-500:disabled {
  background-color: var(--color-canvas-500)
}

.disabled\:sl-bg-canvas-pure:disabled {
  background-color: var(--color-canvas-pure)
}

.disabled\:sl-bg-canvas:disabled {
  background-color: var(--color-canvas)
}

.disabled\:sl-bg-canvas-tint:disabled {
  background-color: var(--color-canvas-tint)
}

.disabled\:sl-bg-canvas-dialog:disabled {
  background-color: var(--color-canvas-dialog)
}

.disabled\:sl-bg-body:disabled {
  background-color: var(--color-text)
}

.disabled\:sl-bg-body-muted:disabled {
  background-color: var(--color-text-muted)
}

.disabled\:sl-bg-body-light:disabled {
  background-color: var(--color-text-light)
}

.sl-bg-none {
  background-image: none
}

.sl-bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.sl-bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops))
}

.sl-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.sl-bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}

.sl-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.sl-bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
}

.sl-bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops))
}

.sl-bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops))
}

.sl-blur-0, .sl-blur-none {
  --tw-blur: blur(0)
}

.sl-blur-sm {
  --tw-blur: blur(4px)
}

.sl-blur {
  --tw-blur: blur(8px)
}

.sl-blur-md {
  --tw-blur: blur(12px)
}

.sl-blur-lg {
  --tw-blur: blur(16px)
}

.sl-blur-xl {
  --tw-blur: blur(24px)
}

.sl-blur-2xl {
  --tw-blur: blur(40px)
}

.sl-blur-3xl {
  --tw-blur: blur(64px)
}

.sl-border-transparent {
  border-color: transparent
}

.sl-border-current {
  border-color: currentColor
}

.sl-border-lighten-100 {
  border-color: var(--color-lighten-100)
}

.sl-border-darken-100 {
  border-color: var(--color-darken-100)
}

.sl-border-primary {
  border-color: var(--color-primary)
}

.sl-border-primary-tint {
  border-color: var(--color-primary-tint)
}

.sl-border-primary-light {
  border-color: var(--color-primary-light)
}

.sl-border-primary-dark {
  border-color: var(--color-primary-dark)
}

.sl-border-primary-darker {
  border-color: var(--color-primary-darker)
}

.sl-border-success {
  border-color: var(--color-success)
}

.sl-border-success-tint {
  border-color: var(--color-success-tint)
}

.sl-border-success-light {
  border-color: var(--color-success-light)
}

.sl-border-success-dark {
  border-color: var(--color-success-dark)
}

.sl-border-success-darker {
  border-color: var(--color-success-darker)
}

.sl-border-warning {
  border-color: var(--color-warning)
}

.sl-border-warning-tint {
  border-color: var(--color-warning-tint)
}

.sl-border-warning-light {
  border-color: var(--color-warning-light)
}

.sl-border-warning-dark {
  border-color: var(--color-warning-dark)
}

.sl-border-warning-darker {
  border-color: var(--color-warning-darker)
}

.sl-border-danger {
  border-color: var(--color-danger)
}

.sl-border-danger-tint {
  border-color: var(--color-danger-tint)
}

.sl-border-danger-light {
  border-color: var(--color-danger-light)
}

.sl-border-danger-dark {
  border-color: var(--color-danger-dark)
}

.sl-border-danger-darker {
  border-color: var(--color-danger-darker)
}

.sl-border-code {
  border-color: var(--color-code)
}

.sl-border-on-code {
  border-color: var(--color-on-code)
}

.sl-border-on-primary {
  border-color: var(--color-on-primary)
}

.sl-border-on-success {
  border-color: var(--color-on-success)
}

.sl-border-on-warning {
  border-color: var(--color-on-warning)
}

.sl-border-on-danger {
  border-color: var(--color-on-danger)
}

.sl-border-light {
  border-color: var(--color-border-light)
}

.sl-border-dark {
  border-color: var(--color-border-dark)
}

.sl-border-button {
  border-color: var(--color-border-button)
}

.sl-border-input {
  border-color: var(--color-border-input)
}

.sl-border-body {
  border-color: var(--color-text)
}

.hover\:sl-border-transparent:hover {
  border-color: transparent
}

.hover\:sl-border-current:hover {
  border-color: currentColor
}

.hover\:sl-border-lighten-100:hover {
  border-color: var(--color-lighten-100)
}

.hover\:sl-border-darken-100:hover {
  border-color: var(--color-darken-100)
}

.hover\:sl-border-primary:hover {
  border-color: var(--color-primary)
}

.hover\:sl-border-primary-tint:hover {
  border-color: var(--color-primary-tint)
}

.hover\:sl-border-primary-light:hover {
  border-color: var(--color-primary-light)
}

.hover\:sl-border-primary-dark:hover {
  border-color: var(--color-primary-dark)
}

.hover\:sl-border-primary-darker:hover {
  border-color: var(--color-primary-darker)
}

.hover\:sl-border-success:hover {
  border-color: var(--color-success)
}

.hover\:sl-border-success-tint:hover {
  border-color: var(--color-success-tint)
}

.hover\:sl-border-success-light:hover {
  border-color: var(--color-success-light)
}

.hover\:sl-border-success-dark:hover {
  border-color: var(--color-success-dark)
}

.hover\:sl-border-success-darker:hover {
  border-color: var(--color-success-darker)
}

.hover\:sl-border-warning:hover {
  border-color: var(--color-warning)
}

.hover\:sl-border-warning-tint:hover {
  border-color: var(--color-warning-tint)
}

.hover\:sl-border-warning-light:hover {
  border-color: var(--color-warning-light)
}

.hover\:sl-border-warning-dark:hover {
  border-color: var(--color-warning-dark)
}

.hover\:sl-border-warning-darker:hover {
  border-color: var(--color-warning-darker)
}

.hover\:sl-border-danger:hover {
  border-color: var(--color-danger)
}

.hover\:sl-border-danger-tint:hover {
  border-color: var(--color-danger-tint)
}

.hover\:sl-border-danger-light:hover {
  border-color: var(--color-danger-light)
}

.hover\:sl-border-danger-dark:hover {
  border-color: var(--color-danger-dark)
}

.hover\:sl-border-danger-darker:hover {
  border-color: var(--color-danger-darker)
}

.hover\:sl-border-code:hover {
  border-color: var(--color-code)
}

.hover\:sl-border-on-code:hover {
  border-color: var(--color-on-code)
}

.hover\:sl-border-on-primary:hover {
  border-color: var(--color-on-primary)
}

.hover\:sl-border-on-success:hover {
  border-color: var(--color-on-success)
}

.hover\:sl-border-on-warning:hover {
  border-color: var(--color-on-warning)
}

.hover\:sl-border-on-danger:hover {
  border-color: var(--color-on-danger)
}

.hover\:sl-border-light:hover {
  border-color: var(--color-border-light)
}

.hover\:sl-border-dark:hover {
  border-color: var(--color-border-dark)
}

.hover\:sl-border-button:hover {
  border-color: var(--color-border-button)
}

.hover\:sl-border-input:hover {
  border-color: var(--color-border-input)
}

.hover\:sl-border-body:hover {
  border-color: var(--color-text)
}

.focus\:sl-border-transparent:focus {
  border-color: transparent
}

.focus\:sl-border-current:focus {
  border-color: currentColor
}

.focus\:sl-border-lighten-100:focus {
  border-color: var(--color-lighten-100)
}

.focus\:sl-border-darken-100:focus {
  border-color: var(--color-darken-100)
}

.focus\:sl-border-primary:focus {
  border-color: var(--color-primary)
}

.focus\:sl-border-primary-tint:focus {
  border-color: var(--color-primary-tint)
}

.focus\:sl-border-primary-light:focus {
  border-color: var(--color-primary-light)
}

.focus\:sl-border-primary-dark:focus {
  border-color: var(--color-primary-dark)
}

.focus\:sl-border-primary-darker:focus {
  border-color: var(--color-primary-darker)
}

.focus\:sl-border-success:focus {
  border-color: var(--color-success)
}

.focus\:sl-border-success-tint:focus {
  border-color: var(--color-success-tint)
}

.focus\:sl-border-success-light:focus {
  border-color: var(--color-success-light)
}

.focus\:sl-border-success-dark:focus {
  border-color: var(--color-success-dark)
}

.focus\:sl-border-success-darker:focus {
  border-color: var(--color-success-darker)
}

.focus\:sl-border-warning:focus {
  border-color: var(--color-warning)
}

.focus\:sl-border-warning-tint:focus {
  border-color: var(--color-warning-tint)
}

.focus\:sl-border-warning-light:focus {
  border-color: var(--color-warning-light)
}

.focus\:sl-border-warning-dark:focus {
  border-color: var(--color-warning-dark)
}

.focus\:sl-border-warning-darker:focus {
  border-color: var(--color-warning-darker)
}

.focus\:sl-border-danger:focus {
  border-color: var(--color-danger)
}

.focus\:sl-border-danger-tint:focus {
  border-color: var(--color-danger-tint)
}

.focus\:sl-border-danger-light:focus {
  border-color: var(--color-danger-light)
}

.focus\:sl-border-danger-dark:focus {
  border-color: var(--color-danger-dark)
}

.focus\:sl-border-danger-darker:focus {
  border-color: var(--color-danger-darker)
}

.focus\:sl-border-code:focus {
  border-color: var(--color-code)
}

.focus\:sl-border-on-code:focus {
  border-color: var(--color-on-code)
}

.focus\:sl-border-on-primary:focus {
  border-color: var(--color-on-primary)
}

.focus\:sl-border-on-success:focus {
  border-color: var(--color-on-success)
}

.focus\:sl-border-on-warning:focus {
  border-color: var(--color-on-warning)
}

.focus\:sl-border-on-danger:focus {
  border-color: var(--color-on-danger)
}

.focus\:sl-border-light:focus {
  border-color: var(--color-border-light)
}

.focus\:sl-border-dark:focus {
  border-color: var(--color-border-dark)
}

.focus\:sl-border-button:focus {
  border-color: var(--color-border-button)
}

.focus\:sl-border-input:focus {
  border-color: var(--color-border-input)
}

.focus\:sl-border-body:focus {
  border-color: var(--color-text)
}

.active\:sl-border-transparent:active {
  border-color: transparent
}

.active\:sl-border-current:active {
  border-color: currentColor
}

.active\:sl-border-lighten-100:active {
  border-color: var(--color-lighten-100)
}

.active\:sl-border-darken-100:active {
  border-color: var(--color-darken-100)
}

.active\:sl-border-primary:active {
  border-color: var(--color-primary)
}

.active\:sl-border-primary-tint:active {
  border-color: var(--color-primary-tint)
}

.active\:sl-border-primary-light:active {
  border-color: var(--color-primary-light)
}

.active\:sl-border-primary-dark:active {
  border-color: var(--color-primary-dark)
}

.active\:sl-border-primary-darker:active {
  border-color: var(--color-primary-darker)
}

.active\:sl-border-success:active {
  border-color: var(--color-success)
}

.active\:sl-border-success-tint:active {
  border-color: var(--color-success-tint)
}

.active\:sl-border-success-light:active {
  border-color: var(--color-success-light)
}

.active\:sl-border-success-dark:active {
  border-color: var(--color-success-dark)
}

.active\:sl-border-success-darker:active {
  border-color: var(--color-success-darker)
}

.active\:sl-border-warning:active {
  border-color: var(--color-warning)
}

.active\:sl-border-warning-tint:active {
  border-color: var(--color-warning-tint)
}

.active\:sl-border-warning-light:active {
  border-color: var(--color-warning-light)
}

.active\:sl-border-warning-dark:active {
  border-color: var(--color-warning-dark)
}

.active\:sl-border-warning-darker:active {
  border-color: var(--color-warning-darker)
}

.active\:sl-border-danger:active {
  border-color: var(--color-danger)
}

.active\:sl-border-danger-tint:active {
  border-color: var(--color-danger-tint)
}

.active\:sl-border-danger-light:active {
  border-color: var(--color-danger-light)
}

.active\:sl-border-danger-dark:active {
  border-color: var(--color-danger-dark)
}

.active\:sl-border-danger-darker:active {
  border-color: var(--color-danger-darker)
}

.active\:sl-border-code:active {
  border-color: var(--color-code)
}

.active\:sl-border-on-code:active {
  border-color: var(--color-on-code)
}

.active\:sl-border-on-primary:active {
  border-color: var(--color-on-primary)
}

.active\:sl-border-on-success:active {
  border-color: var(--color-on-success)
}

.active\:sl-border-on-warning:active {
  border-color: var(--color-on-warning)
}

.active\:sl-border-on-danger:active {
  border-color: var(--color-on-danger)
}

.active\:sl-border-light:active {
  border-color: var(--color-border-light)
}

.active\:sl-border-dark:active {
  border-color: var(--color-border-dark)
}

.active\:sl-border-button:active {
  border-color: var(--color-border-button)
}

.active\:sl-border-input:active {
  border-color: var(--color-border-input)
}

.active\:sl-border-body:active {
  border-color: var(--color-text)
}

.sl-rounded-none {
  border-radius: 0
}

.sl-rounded-sm {
  border-radius: 1px
}

.sl-rounded {
  border-radius: 2px
}

.sl-rounded-lg {
  border-radius: 5px
}

.sl-rounded-xl {
  border-radius: 7px
}

.sl-rounded-full {
  border-radius: 9999px
}

.sl-rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.sl-rounded-r-none {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.sl-rounded-b-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.sl-rounded-l-none {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.sl-rounded-t-sm {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px
}

.sl-rounded-r-sm {
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px
}

.sl-rounded-b-sm {
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px
}

.sl-rounded-l-sm {
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px
}

.sl-rounded-t {
  border-top-left-radius: 2px
}

.sl-rounded-r, .sl-rounded-t {
  border-top-right-radius: 2px
}

.sl-rounded-b, .sl-rounded-r {
  border-bottom-right-radius: 2px
}

.sl-rounded-b, .sl-rounded-l {
  border-bottom-left-radius: 2px
}

.sl-rounded-l {
  border-top-left-radius: 2px
}

.sl-rounded-t-lg {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px
}

.sl-rounded-r-lg {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px
}

.sl-rounded-b-lg {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px
}

.sl-rounded-l-lg {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px
}

.sl-rounded-t-xl {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px
}

.sl-rounded-r-xl {
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px
}

.sl-rounded-b-xl {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px
}

.sl-rounded-l-xl {
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px
}

.sl-rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px
}

.sl-rounded-r-full {
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px
}

.sl-rounded-b-full {
  border-bottom-left-radius: 9999px;
  border-bottom-right-radius: 9999px
}

.sl-rounded-l-full {
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px
}

.sl-rounded-tl-none {
  border-top-left-radius: 0
}

.sl-rounded-tr-none {
  border-top-right-radius: 0
}

.sl-rounded-br-none {
  border-bottom-right-radius: 0
}

.sl-rounded-bl-none {
  border-bottom-left-radius: 0
}

.sl-rounded-tl-sm {
  border-top-left-radius: 1px
}

.sl-rounded-tr-sm {
  border-top-right-radius: 1px
}

.sl-rounded-br-sm {
  border-bottom-right-radius: 1px
}

.sl-rounded-bl-sm {
  border-bottom-left-radius: 1px
}

.sl-rounded-tl {
  border-top-left-radius: 2px
}

.sl-rounded-tr {
  border-top-right-radius: 2px
}

.sl-rounded-br {
  border-bottom-right-radius: 2px
}

.sl-rounded-bl {
  border-bottom-left-radius: 2px
}

.sl-rounded-tl-lg {
  border-top-left-radius: 5px
}

.sl-rounded-tr-lg {
  border-top-right-radius: 5px
}

.sl-rounded-br-lg {
  border-bottom-right-radius: 5px
}

.sl-rounded-bl-lg {
  border-bottom-left-radius: 5px
}

.sl-rounded-tl-xl {
  border-top-left-radius: 7px
}

.sl-rounded-tr-xl {
  border-top-right-radius: 7px
}

.sl-rounded-br-xl {
  border-bottom-right-radius: 7px
}

.sl-rounded-bl-xl {
  border-bottom-left-radius: 7px
}

.sl-rounded-tl-full {
  border-top-left-radius: 9999px
}

.sl-rounded-tr-full {
  border-top-right-radius: 9999px
}

.sl-rounded-br-full {
  border-bottom-right-radius: 9999px
}

.sl-rounded-bl-full {
  border-bottom-left-radius: 9999px
}

.sl-border-solid {
  border-style: solid
}

.sl-border-dashed {
  border-style: dashed
}

.sl-border-dotted {
  border-style: dotted
}

.sl-border-double {
  border-style: double
}

.sl-border-none {
  border-style: none
}

.sl-border-0 {
  border-width: 0
}

.sl-border-2 {
  border-width: 2px
}

.sl-border-4 {
  border-width: 4px
}

.sl-border-8 {
  border-width: 8px
}

.sl-border {
  border-width: 1px
}

.sl-border-t-0 {
  border-top-width: 0
}

.sl-border-r-0 {
  border-right-width: 0
}

.sl-border-b-0 {
  border-bottom-width: 0
}

.sl-border-l-0 {
  border-left-width: 0
}

.sl-border-t-2 {
  border-top-width: 2px
}

.sl-border-r-2 {
  border-right-width: 2px
}

.sl-border-b-2 {
  border-bottom-width: 2px
}

.sl-border-l-2 {
  border-left-width: 2px
}

.sl-border-t-4 {
  border-top-width: 4px
}

.sl-border-r-4 {
  border-right-width: 4px
}

.sl-border-b-4 {
  border-bottom-width: 4px
}

.sl-border-l-4 {
  border-left-width: 4px
}

.sl-border-t-8 {
  border-top-width: 8px
}

.sl-border-r-8 {
  border-right-width: 8px
}

.sl-border-b-8 {
  border-bottom-width: 8px
}

.sl-border-l-8 {
  border-left-width: 8px
}

.sl-border-t {
  border-top-width: 1px
}

.sl-border-r {
  border-right-width: 1px
}

.sl-border-b {
  border-bottom-width: 1px
}

.sl-border-l {
  border-left-width: 1px
}

* {
  --tw-shadow: 0 0 #0000
}

.sl-shadow-sm {
  --tw-shadow: var(--shadow-sm);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.sl-shadow, .sl-shadow-md {
  --tw-shadow: var(--shadow-md)
}

.sl-shadow, .sl-shadow-lg, .sl-shadow-md {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.sl-shadow-lg {
  --tw-shadow: var(--shadow-lg)
}

.sl-shadow-xl {
  --tw-shadow: var(--shadow-xl)
}

.sl-shadow-2xl, .sl-shadow-xl {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.sl-shadow-2xl {
  --tw-shadow: var(--shadow-2xl)
}

.hover\:sl-shadow-sm:hover {
  --tw-shadow: var(--shadow-sm);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:sl-shadow-md:hover, .hover\:sl-shadow:hover {
  --tw-shadow: var(--shadow-md)
}

.hover\:sl-shadow-lg:hover, .hover\:sl-shadow-md:hover, .hover\:sl-shadow:hover {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:sl-shadow-lg:hover {
  --tw-shadow: var(--shadow-lg)
}

.hover\:sl-shadow-xl:hover {
  --tw-shadow: var(--shadow-xl)
}

.hover\:sl-shadow-2xl:hover, .hover\:sl-shadow-xl:hover {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:sl-shadow-2xl:hover {
  --tw-shadow: var(--shadow-2xl)
}

.focus\:sl-shadow-sm:focus {
  --tw-shadow: var(--shadow-sm);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:sl-shadow-md:focus, .focus\:sl-shadow:focus {
  --tw-shadow: var(--shadow-md)
}

.focus\:sl-shadow-lg:focus, .focus\:sl-shadow-md:focus, .focus\:sl-shadow:focus {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:sl-shadow-lg:focus {
  --tw-shadow: var(--shadow-lg)
}

.focus\:sl-shadow-xl:focus {
  --tw-shadow: var(--shadow-xl)
}

.focus\:sl-shadow-2xl:focus, .focus\:sl-shadow-xl:focus {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:sl-shadow-2xl:focus {
  --tw-shadow: var(--shadow-2xl)
}

.sl-box-border {
  box-sizing: border-box
}

.sl-box-content {
  box-sizing: initial
}

.sl-cursor-auto {
  cursor: auto
}

.sl-cursor {
  cursor: default
}

.sl-cursor-pointer {
  cursor: pointer
}

.sl-cursor-wait {
  cursor: wait
}

.sl-cursor-text {
  cursor: text
}

.sl-cursor-move {
  cursor: move
}

.sl-cursor-not-allowed {
  cursor: not-allowed
}

.sl-cursor-zoom-in {
  cursor: zoom-in
}

.sl-cursor-zoom-out {
  cursor: zoom-out
}

.sl-block {
  display: block
}

.sl-inline-block {
  display: inline-block
}

.sl-inline {
  display: inline
}

.sl-flex {
  display: flex
}

.sl-inline-flex {
  display: inline-flex
}

.sl-table {
  display: table
}

.sl-inline-table {
  display: inline-table
}

.sl-table-caption {
  display: table-caption
}

.sl-table-cell {
  display: table-cell
}

.sl-table-column {
  display: table-column
}

.sl-table-column-group {
  display: table-column-group
}

.sl-table-footer-group {
  display: table-footer-group
}

.sl-table-header-group {
  display: table-header-group
}

.sl-table-row-group {
  display: table-row-group
}

.sl-table-row {
  display: table-row
}

.sl-flow-root {
  display: flow-root
}

.sl-grid {
  display: grid
}

.sl-inline-grid {
  display: inline-grid
}

.sl-contents {
  display: contents
}

.sl-list-item {
  display: list-item
}

.sl-hidden {
  display: none
}

.sl-drop-shadow {
  --tw-drop-shadow: drop-shadow(var(--drop-shadow-default1)) drop-shadow(var(--drop-shadow-default2))
}

.sl-filter {
  --tw-blur: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-brightness: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-contrast: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-grayscale: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-hue-rotate: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-invert: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-saturate: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-sepia: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-drop-shadow: var(--tw-empty, /*!*/
    /*!*/
  );
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.sl-filter-none {
  filter: none
}

.sl-flex-1 {
  flex: 1 1
}

.sl-flex-auto {
  flex: 1 1 auto
}

.sl-flex-initial {
  flex: 0 1 auto
}

.sl-flex-none {
  flex: none
}

.sl-flex-row {
  flex-direction: row
}

.sl-flex-row-reverse {
  flex-direction: row-reverse
}

.sl-flex-col {
  flex-direction: column
}

.sl-flex-col-reverse {
  flex-direction: column-reverse
}

.sl-flex-grow-0 {
  flex-grow: 0
}

.sl-flex-grow {
  flex-grow: 1
}

.sl-flex-shrink-0 {
  flex-shrink: 0
}

.sl-flex-shrink {
  flex-shrink: 1
}

.sl-flex-wrap {
  flex-wrap: wrap
}

.sl-flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.sl-flex-nowrap {
  flex-wrap: nowrap
}

.sl-font-sans, .sl-font-ui {
  font-family: var(--font-ui)
}

.sl-font-prose {
  font-family: var(--font-prose)
}

.sl-font-mono {
  font-family: var(--font-mono)
}

.sl-text-2xs {
  font-size: 9px
}

.sl-text-xs {
  font-size: 10px
}

.sl-text-sm {
  font-size: 11px
}

.sl-text-base {
  font-size: 12px
}

.sl-text-lg {
  font-size: 14px
}

.sl-text-xl {
  font-size: 16px
}

.sl-text-2xl {
  font-size: 20px
}

.sl-text-3xl {
  font-size: 24px
}

.sl-text-4xl {
  font-size: 28px
}

.sl-text-5xl {
  font-size: 36px
}

.sl-text-6xl {
  font-size: 44px
}

.sl-text-paragraph-leading {
  font-size: var(--fs-paragraph-leading)
}

.sl-text-paragraph {
  font-size: var(--fs-paragraph)
}

.sl-text-paragraph-small {
  font-size: var(--fs-paragraph-small)
}

.sl-text-paragraph-tiny {
  font-size: var(--fs-paragraph-tiny)
}

.sl-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.sl-subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto
}

.sl-italic {
  font-style: italic
}

.sl-not-italic {
  font-style: normal
}

.sl-font-light {
  font-weight: 300
}

.sl-font-normal {
  font-weight: 400
}

.sl-font-medium {
  font-weight: 500
}

.sl-font-semibold {
  font-weight: 600
}

.sl-font-bold {
  font-weight: 700
}

.sl-h-0 {
  height: 0
}

.sl-h-1 {
  height: 4px
}

.sl-h-2 {
  height: 8px
}

.sl-h-3 {
  height: 12px
}

.sl-h-4 {
  height: 16px
}

.sl-h-5 {
  height: 20px
}

.sl-h-6 {
  height: 24px
}

.sl-h-7 {
  height: 28px
}

.sl-h-8 {
  height: 32px
}

.sl-h-9 {
  height: 36px
}

.sl-h-10 {
  height: 40px
}

.sl-h-11 {
  height: 44px
}

.sl-h-12 {
  height: 48px
}

.sl-h-14 {
  height: 56px
}

.sl-h-16 {
  height: 64px
}

.sl-h-20 {
  height: 80px
}

.sl-h-24 {
  height: 96px
}

.sl-h-32 {
  height: 128px
}

.sl-h-40 {
  height: 160px
}

.sl-h-60 {
  height: 240px
}

.sl-h-80 {
  height: 320px
}

.sl-h-auto {
  height: auto
}

.sl-h-px {
  height: 1px
}

.sl-h-0\.5 {
  height: 2px
}

.sl-h-1\.5 {
  height: 6px
}

.sl-h-2\.5 {
  height: 10px
}

.sl-h-3\.5 {
  height: 14px
}

.sl-h-xs {
  height: 20px
}

.sl-h-sm {
  height: 24px
}

.sl-h-md {
  height: 32px
}

.sl-h-lg {
  height: 36px
}

.sl-h-xl {
  height: 44px
}

.sl-h-2xl {
  height: 52px
}

.sl-h-3xl {
  height: 60px
}

.sl-h-full {
  height: 100%
}

.sl-h-screen {
  height: 100vh
}

.sl-inset-0 {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0
}

.sl-inset-1 {
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px
}

.sl-inset-2 {
  bottom: 8px;
  left: 8px;
  right: 8px;
  top: 8px
}

.sl-inset-3 {
  bottom: 12px;
  left: 12px;
  right: 12px;
  top: 12px
}

.sl-inset-4 {
  bottom: 16px;
  left: 16px;
  right: 16px;
  top: 16px
}

.sl-inset-5 {
  bottom: 20px;
  left: 20px;
  right: 20px;
  top: 20px
}

.sl-inset-6 {
  bottom: 24px;
  left: 24px;
  right: 24px;
  top: 24px
}

.sl-inset-7 {
  bottom: 28px;
  left: 28px;
  right: 28px;
  top: 28px
}

.sl-inset-8 {
  bottom: 32px;
  left: 32px;
  right: 32px;
  top: 32px
}

.sl-inset-9 {
  bottom: 36px;
  left: 36px;
  right: 36px;
  top: 36px
}

.sl-inset-10 {
  bottom: 40px;
  left: 40px;
  right: 40px;
  top: 40px
}

.sl-inset-11 {
  bottom: 44px;
  left: 44px;
  right: 44px;
  top: 44px
}

.sl-inset-12 {
  bottom: 48px;
  left: 48px;
  right: 48px;
  top: 48px
}

.sl-inset-14 {
  bottom: 56px;
  left: 56px;
  right: 56px;
  top: 56px
}

.sl-inset-16 {
  bottom: 64px;
  left: 64px;
  right: 64px;
  top: 64px
}

.sl-inset-20 {
  bottom: 80px;
  left: 80px;
  right: 80px;
  top: 80px
}

.sl-inset-24 {
  bottom: 96px;
  left: 96px;
  right: 96px;
  top: 96px
}

.sl-inset-32 {
  bottom: 128px;
  left: 128px;
  right: 128px;
  top: 128px
}

.sl-inset-40 {
  bottom: 160px;
  left: 160px;
  right: 160px;
  top: 160px
}

.sl-inset-60 {
  bottom: 240px;
  left: 240px;
  right: 240px;
  top: 240px
}

.sl-inset-80 {
  bottom: 320px;
  left: 320px;
  right: 320px;
  top: 320px
}

.sl-inset-auto {
  bottom: auto;
  left: auto;
  right: auto;
  top: auto
}

.sl-inset-px {
  bottom: 1px;
  left: 1px;
  right: 1px;
  top: 1px
}

.sl-inset-0\.5 {
  bottom: 2px;
  left: 2px;
  right: 2px;
  top: 2px
}

.sl-inset-1\.5 {
  bottom: 6px;
  left: 6px;
  right: 6px;
  top: 6px
}

.sl-inset-2\.5 {
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 10px
}

.sl-inset-3\.5 {
  bottom: 14px;
  left: 14px;
  right: 14px;
  top: 14px
}

.sl--inset-0 {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0
}

.sl--inset-1 {
  bottom: -4px;
  left: -4px;
  right: -4px;
  top: -4px
}

.sl--inset-2 {
  bottom: -8px;
  left: -8px;
  right: -8px;
  top: -8px
}

.sl--inset-3 {
  bottom: -12px;
  left: -12px;
  right: -12px;
  top: -12px
}

.sl--inset-4 {
  bottom: -16px;
  left: -16px;
  right: -16px;
  top: -16px
}

.sl--inset-5 {
  bottom: -20px;
  left: -20px;
  right: -20px;
  top: -20px
}

.sl--inset-6 {
  bottom: -24px;
  left: -24px;
  right: -24px;
  top: -24px
}

.sl--inset-7 {
  bottom: -28px;
  left: -28px;
  right: -28px;
  top: -28px
}

.sl--inset-8 {
  bottom: -32px;
  left: -32px;
  right: -32px;
  top: -32px
}

.sl--inset-9 {
  bottom: -36px;
  left: -36px;
  right: -36px;
  top: -36px
}

.sl--inset-10 {
  bottom: -40px;
  left: -40px;
  right: -40px;
  top: -40px
}

.sl--inset-11 {
  bottom: -44px;
  left: -44px;
  right: -44px;
  top: -44px
}

.sl--inset-12 {
  bottom: -48px;
  left: -48px;
  right: -48px;
  top: -48px
}

.sl--inset-14 {
  bottom: -56px;
  left: -56px;
  right: -56px;
  top: -56px
}

.sl--inset-16 {
  bottom: -64px;
  left: -64px;
  right: -64px;
  top: -64px
}

.sl--inset-20 {
  bottom: -80px;
  left: -80px;
  right: -80px;
  top: -80px
}

.sl--inset-24 {
  bottom: -96px;
  left: -96px;
  right: -96px;
  top: -96px
}

.sl--inset-32 {
  bottom: -128px;
  left: -128px;
  right: -128px;
  top: -128px
}

.sl--inset-40 {
  bottom: -160px;
  left: -160px;
  right: -160px;
  top: -160px
}

.sl--inset-60 {
  bottom: -240px;
  left: -240px;
  right: -240px;
  top: -240px
}

.sl--inset-80 {
  bottom: -320px;
  left: -320px;
  right: -320px;
  top: -320px
}

.sl--inset-px {
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px
}

.sl--inset-0\.5 {
  bottom: -2px;
  left: -2px;
  right: -2px;
  top: -2px
}

.sl--inset-1\.5 {
  bottom: -6px;
  left: -6px;
  right: -6px;
  top: -6px
}

.sl--inset-2\.5 {
  bottom: -10px;
  left: -10px;
  right: -10px;
  top: -10px
}

.sl--inset-3\.5 {
  bottom: -14px;
  left: -14px;
  right: -14px;
  top: -14px
}

.sl-inset-y-0 {
  bottom: 0;
  top: 0
}

.sl-inset-x-0 {
  left: 0;
  right: 0
}

.sl-inset-y-1 {
  bottom: 4px;
  top: 4px
}

.sl-inset-x-1 {
  left: 4px;
  right: 4px
}

.sl-inset-y-2 {
  bottom: 8px;
  top: 8px
}

.sl-inset-x-2 {
  left: 8px;
  right: 8px
}

.sl-inset-y-3 {
  bottom: 12px;
  top: 12px
}

.sl-inset-x-3 {
  left: 12px;
  right: 12px
}

.sl-inset-y-4 {
  bottom: 16px;
  top: 16px
}

.sl-inset-x-4 {
  left: 16px;
  right: 16px
}

.sl-inset-y-5 {
  bottom: 20px;
  top: 20px
}

.sl-inset-x-5 {
  left: 20px;
  right: 20px
}

.sl-inset-y-6 {
  bottom: 24px;
  top: 24px
}

.sl-inset-x-6 {
  left: 24px;
  right: 24px
}

.sl-inset-y-7 {
  bottom: 28px;
  top: 28px
}

.sl-inset-x-7 {
  left: 28px;
  right: 28px
}

.sl-inset-y-8 {
  bottom: 32px;
  top: 32px
}

.sl-inset-x-8 {
  left: 32px;
  right: 32px
}

.sl-inset-y-9 {
  bottom: 36px;
  top: 36px
}

.sl-inset-x-9 {
  left: 36px;
  right: 36px
}

.sl-inset-y-10 {
  bottom: 40px;
  top: 40px
}

.sl-inset-x-10 {
  left: 40px;
  right: 40px
}

.sl-inset-y-11 {
  bottom: 44px;
  top: 44px
}

.sl-inset-x-11 {
  left: 44px;
  right: 44px
}

.sl-inset-y-12 {
  bottom: 48px;
  top: 48px
}

.sl-inset-x-12 {
  left: 48px;
  right: 48px
}

.sl-inset-y-14 {
  bottom: 56px;
  top: 56px
}

.sl-inset-x-14 {
  left: 56px;
  right: 56px
}

.sl-inset-y-16 {
  bottom: 64px;
  top: 64px
}

.sl-inset-x-16 {
  left: 64px;
  right: 64px
}

.sl-inset-y-20 {
  bottom: 80px;
  top: 80px
}

.sl-inset-x-20 {
  left: 80px;
  right: 80px
}

.sl-inset-y-24 {
  bottom: 96px;
  top: 96px
}

.sl-inset-x-24 {
  left: 96px;
  right: 96px
}

.sl-inset-y-32 {
  bottom: 128px;
  top: 128px
}

.sl-inset-x-32 {
  left: 128px;
  right: 128px
}

.sl-inset-y-40 {
  bottom: 160px;
  top: 160px
}

.sl-inset-x-40 {
  left: 160px;
  right: 160px
}

.sl-inset-y-60 {
  bottom: 240px;
  top: 240px
}

.sl-inset-x-60 {
  left: 240px;
  right: 240px
}

.sl-inset-y-80 {
  bottom: 320px;
  top: 320px
}

.sl-inset-x-80 {
  left: 320px;
  right: 320px
}

.sl-inset-y-auto {
  bottom: auto;
  top: auto
}

.sl-inset-x-auto {
  left: auto;
  right: auto
}

.sl-inset-y-px {
  bottom: 1px;
  top: 1px
}

.sl-inset-x-px {
  left: 1px;
  right: 1px
}

.sl-inset-y-0\.5 {
  bottom: 2px;
  top: 2px
}

.sl-inset-x-0\.5 {
  left: 2px;
  right: 2px
}

.sl-inset-y-1\.5 {
  bottom: 6px;
  top: 6px
}

.sl-inset-x-1\.5 {
  left: 6px;
  right: 6px
}

.sl-inset-y-2\.5 {
  bottom: 10px;
  top: 10px
}

.sl-inset-x-2\.5 {
  left: 10px;
  right: 10px
}

.sl-inset-y-3\.5 {
  bottom: 14px;
  top: 14px
}

.sl-inset-x-3\.5 {
  left: 14px;
  right: 14px
}

.sl--inset-y-0 {
  bottom: 0;
  top: 0
}

.sl--inset-x-0 {
  left: 0;
  right: 0
}

.sl--inset-y-1 {
  bottom: -4px;
  top: -4px
}

.sl--inset-x-1 {
  left: -4px;
  right: -4px
}

.sl--inset-y-2 {
  bottom: -8px;
  top: -8px
}

.sl--inset-x-2 {
  left: -8px;
  right: -8px
}

.sl--inset-y-3 {
  bottom: -12px;
  top: -12px
}

.sl--inset-x-3 {
  left: -12px;
  right: -12px
}

.sl--inset-y-4 {
  bottom: -16px;
  top: -16px
}

.sl--inset-x-4 {
  left: -16px;
  right: -16px
}

.sl--inset-y-5 {
  bottom: -20px;
  top: -20px
}

.sl--inset-x-5 {
  left: -20px;
  right: -20px
}

.sl--inset-y-6 {
  bottom: -24px;
  top: -24px
}

.sl--inset-x-6 {
  left: -24px;
  right: -24px
}

.sl--inset-y-7 {
  bottom: -28px;
  top: -28px
}

.sl--inset-x-7 {
  left: -28px;
  right: -28px
}

.sl--inset-y-8 {
  bottom: -32px;
  top: -32px
}

.sl--inset-x-8 {
  left: -32px;
  right: -32px
}

.sl--inset-y-9 {
  bottom: -36px;
  top: -36px
}

.sl--inset-x-9 {
  left: -36px;
  right: -36px
}

.sl--inset-y-10 {
  bottom: -40px;
  top: -40px
}

.sl--inset-x-10 {
  left: -40px;
  right: -40px
}

.sl--inset-y-11 {
  bottom: -44px;
  top: -44px
}

.sl--inset-x-11 {
  left: -44px;
  right: -44px
}

.sl--inset-y-12 {
  bottom: -48px;
  top: -48px
}

.sl--inset-x-12 {
  left: -48px;
  right: -48px
}

.sl--inset-y-14 {
  bottom: -56px;
  top: -56px
}

.sl--inset-x-14 {
  left: -56px;
  right: -56px
}

.sl--inset-y-16 {
  bottom: -64px;
  top: -64px
}

.sl--inset-x-16 {
  left: -64px;
  right: -64px
}

.sl--inset-y-20 {
  bottom: -80px;
  top: -80px
}

.sl--inset-x-20 {
  left: -80px;
  right: -80px
}

.sl--inset-y-24 {
  bottom: -96px;
  top: -96px
}

.sl--inset-x-24 {
  left: -96px;
  right: -96px
}

.sl--inset-y-32 {
  bottom: -128px;
  top: -128px
}

.sl--inset-x-32 {
  left: -128px;
  right: -128px
}

.sl--inset-y-40 {
  bottom: -160px;
  top: -160px
}

.sl--inset-x-40 {
  left: -160px;
  right: -160px
}

.sl--inset-y-60 {
  bottom: -240px;
  top: -240px
}

.sl--inset-x-60 {
  left: -240px;
  right: -240px
}

.sl--inset-y-80 {
  bottom: -320px;
  top: -320px
}

.sl--inset-x-80 {
  left: -320px;
  right: -320px
}

.sl--inset-y-px {
  bottom: -1px;
  top: -1px
}

.sl--inset-x-px {
  left: -1px;
  right: -1px
}

.sl--inset-y-0\.5 {
  bottom: -2px;
  top: -2px
}

.sl--inset-x-0\.5 {
  left: -2px;
  right: -2px
}

.sl--inset-y-1\.5 {
  bottom: -6px;
  top: -6px
}

.sl--inset-x-1\.5 {
  left: -6px;
  right: -6px
}

.sl--inset-y-2\.5 {
  bottom: -10px;
  top: -10px
}

.sl--inset-x-2\.5 {
  left: -10px;
  right: -10px
}

.sl--inset-y-3\.5 {
  bottom: -14px;
  top: -14px
}

.sl--inset-x-3\.5 {
  left: -14px;
  right: -14px
}

.sl-top-0 {
  top: 0
}

.sl-right-0 {
  right: 0
}

.sl-bottom-0 {
  bottom: 0
}

.sl-left-0 {
  left: 0
}

.sl-top-1 {
  top: 4px
}

.sl-right-1 {
  right: 4px
}

.sl-bottom-1 {
  bottom: 4px
}

.sl-left-1 {
  left: 4px
}

.sl-top-2 {
  top: 8px
}

.sl-right-2 {
  right: 8px
}

.sl-bottom-2 {
  bottom: 8px
}

.sl-left-2 {
  left: 8px
}

.sl-top-3 {
  top: 12px
}

.sl-right-3 {
  right: 12px
}

.sl-bottom-3 {
  bottom: 12px
}

.sl-left-3 {
  left: 12px
}

.sl-top-4 {
  top: 16px
}

.sl-right-4 {
  right: 16px
}

.sl-bottom-4 {
  bottom: 16px
}

.sl-left-4 {
  left: 16px
}

.sl-top-5 {
  top: 20px
}

.sl-right-5 {
  right: 20px
}

.sl-bottom-5 {
  bottom: 20px
}

.sl-left-5 {
  left: 20px
}

.sl-top-6 {
  top: 24px
}

.sl-right-6 {
  right: 24px
}

.sl-bottom-6 {
  bottom: 24px
}

.sl-left-6 {
  left: 24px
}

.sl-top-7 {
  top: 28px
}

.sl-right-7 {
  right: 28px
}

.sl-bottom-7 {
  bottom: 28px
}

.sl-left-7 {
  left: 28px
}

.sl-top-8 {
  top: 32px
}

.sl-right-8 {
  right: 32px
}

.sl-bottom-8 {
  bottom: 32px
}

.sl-left-8 {
  left: 32px
}

.sl-top-9 {
  top: 36px
}

.sl-right-9 {
  right: 36px
}

.sl-bottom-9 {
  bottom: 36px
}

.sl-left-9 {
  left: 36px
}

.sl-top-10 {
  top: 40px
}

.sl-right-10 {
  right: 40px
}

.sl-bottom-10 {
  bottom: 40px
}

.sl-left-10 {
  left: 40px
}

.sl-top-11 {
  top: 44px
}

.sl-right-11 {
  right: 44px
}

.sl-bottom-11 {
  bottom: 44px
}

.sl-left-11 {
  left: 44px
}

.sl-top-12 {
  top: 48px
}

.sl-right-12 {
  right: 48px
}

.sl-bottom-12 {
  bottom: 48px
}

.sl-left-12 {
  left: 48px
}

.sl-top-14 {
  top: 56px
}

.sl-right-14 {
  right: 56px
}

.sl-bottom-14 {
  bottom: 56px
}

.sl-left-14 {
  left: 56px
}

.sl-top-16 {
  top: 64px
}

.sl-right-16 {
  right: 64px
}

.sl-bottom-16 {
  bottom: 64px
}

.sl-left-16 {
  left: 64px
}

.sl-top-20 {
  top: 80px
}

.sl-right-20 {
  right: 80px
}

.sl-bottom-20 {
  bottom: 80px
}

.sl-left-20 {
  left: 80px
}

.sl-top-24 {
  top: 96px
}

.sl-right-24 {
  right: 96px
}

.sl-bottom-24 {
  bottom: 96px
}

.sl-left-24 {
  left: 96px
}

.sl-top-32 {
  top: 128px
}

.sl-right-32 {
  right: 128px
}

.sl-bottom-32 {
  bottom: 128px
}

.sl-left-32 {
  left: 128px
}

.sl-top-40 {
  top: 160px
}

.sl-right-40 {
  right: 160px
}

.sl-bottom-40 {
  bottom: 160px
}

.sl-left-40 {
  left: 160px
}

.sl-top-60 {
  top: 240px
}

.sl-right-60 {
  right: 240px
}

.sl-bottom-60 {
  bottom: 240px
}

.sl-left-60 {
  left: 240px
}

.sl-top-80 {
  top: 320px
}

.sl-right-80 {
  right: 320px
}

.sl-bottom-80 {
  bottom: 320px
}

.sl-left-80 {
  left: 320px
}

.sl-top-auto {
  top: auto
}

.sl-right-auto {
  right: auto
}

.sl-bottom-auto {
  bottom: auto
}

.sl-left-auto {
  left: auto
}

.sl-top-px {
  top: 1px
}

.sl-right-px {
  right: 1px
}

.sl-bottom-px {
  bottom: 1px
}

.sl-left-px {
  left: 1px
}

.sl-top-0\.5 {
  top: 2px
}

.sl-right-0\.5 {
  right: 2px
}

.sl-bottom-0\.5 {
  bottom: 2px
}

.sl-left-0\.5 {
  left: 2px
}

.sl-top-1\.5 {
  top: 6px
}

.sl-right-1\.5 {
  right: 6px
}

.sl-bottom-1\.5 {
  bottom: 6px
}

.sl-left-1\.5 {
  left: 6px
}

.sl-top-2\.5 {
  top: 10px
}

.sl-right-2\.5 {
  right: 10px
}

.sl-bottom-2\.5 {
  bottom: 10px
}

.sl-left-2\.5 {
  left: 10px
}

.sl-top-3\.5 {
  top: 14px
}

.sl-right-3\.5 {
  right: 14px
}

.sl-bottom-3\.5 {
  bottom: 14px
}

.sl-left-3\.5 {
  left: 14px
}

.sl--top-0 {
  top: 0
}

.sl--right-0 {
  right: 0
}

.sl--bottom-0 {
  bottom: 0
}

.sl--left-0 {
  left: 0
}

.sl--top-1 {
  top: -4px
}

.sl--right-1 {
  right: -4px
}

.sl--bottom-1 {
  bottom: -4px
}

.sl--left-1 {
  left: -4px
}

.sl--top-2 {
  top: -8px
}

.sl--right-2 {
  right: -8px
}

.sl--bottom-2 {
  bottom: -8px
}

.sl--left-2 {
  left: -8px
}

.sl--top-3 {
  top: -12px
}

.sl--right-3 {
  right: -12px
}

.sl--bottom-3 {
  bottom: -12px
}

.sl--left-3 {
  left: -12px
}

.sl--top-4 {
  top: -16px
}

.sl--right-4 {
  right: -16px
}

.sl--bottom-4 {
  bottom: -16px
}

.sl--left-4 {
  left: -16px
}

.sl--top-5 {
  top: -20px
}

.sl--right-5 {
  right: -20px
}

.sl--bottom-5 {
  bottom: -20px
}

.sl--left-5 {
  left: -20px
}

.sl--top-6 {
  top: -24px
}

.sl--right-6 {
  right: -24px
}

.sl--bottom-6 {
  bottom: -24px
}

.sl--left-6 {
  left: -24px
}

.sl--top-7 {
  top: -28px
}

.sl--right-7 {
  right: -28px
}

.sl--bottom-7 {
  bottom: -28px
}

.sl--left-7 {
  left: -28px
}

.sl--top-8 {
  top: -32px
}

.sl--right-8 {
  right: -32px
}

.sl--bottom-8 {
  bottom: -32px
}

.sl--left-8 {
  left: -32px
}

.sl--top-9 {
  top: -36px
}

.sl--right-9 {
  right: -36px
}

.sl--bottom-9 {
  bottom: -36px
}

.sl--left-9 {
  left: -36px
}

.sl--top-10 {
  top: -40px
}

.sl--right-10 {
  right: -40px
}

.sl--bottom-10 {
  bottom: -40px
}

.sl--left-10 {
  left: -40px
}

.sl--top-11 {
  top: -44px
}

.sl--right-11 {
  right: -44px
}

.sl--bottom-11 {
  bottom: -44px
}

.sl--left-11 {
  left: -44px
}

.sl--top-12 {
  top: -48px
}

.sl--right-12 {
  right: -48px
}

.sl--bottom-12 {
  bottom: -48px
}

.sl--left-12 {
  left: -48px
}

.sl--top-14 {
  top: -56px
}

.sl--right-14 {
  right: -56px
}

.sl--bottom-14 {
  bottom: -56px
}

.sl--left-14 {
  left: -56px
}

.sl--top-16 {
  top: -64px
}

.sl--right-16 {
  right: -64px
}

.sl--bottom-16 {
  bottom: -64px
}

.sl--left-16 {
  left: -64px
}

.sl--top-20 {
  top: -80px
}

.sl--right-20 {
  right: -80px
}

.sl--bottom-20 {
  bottom: -80px
}

.sl--left-20 {
  left: -80px
}

.sl--top-24 {
  top: -96px
}

.sl--right-24 {
  right: -96px
}

.sl--bottom-24 {
  bottom: -96px
}

.sl--left-24 {
  left: -96px
}

.sl--top-32 {
  top: -128px
}

.sl--right-32 {
  right: -128px
}

.sl--bottom-32 {
  bottom: -128px
}

.sl--left-32 {
  left: -128px
}

.sl--top-40 {
  top: -160px
}

.sl--right-40 {
  right: -160px
}

.sl--bottom-40 {
  bottom: -160px
}

.sl--left-40 {
  left: -160px
}

.sl--top-60 {
  top: -240px
}

.sl--right-60 {
  right: -240px
}

.sl--bottom-60 {
  bottom: -240px
}

.sl--left-60 {
  left: -240px
}

.sl--top-80 {
  top: -320px
}

.sl--right-80 {
  right: -320px
}

.sl--bottom-80 {
  bottom: -320px
}

.sl--left-80 {
  left: -320px
}

.sl--top-px {
  top: -1px
}

.sl--right-px {
  right: -1px
}

.sl--bottom-px {
  bottom: -1px
}

.sl--left-px {
  left: -1px
}

.sl--top-0\.5 {
  top: -2px
}

.sl--right-0\.5 {
  right: -2px
}

.sl--bottom-0\.5 {
  bottom: -2px
}

.sl--left-0\.5 {
  left: -2px
}

.sl--top-1\.5 {
  top: -6px
}

.sl--right-1\.5 {
  right: -6px
}

.sl--bottom-1\.5 {
  bottom: -6px
}

.sl--left-1\.5 {
  left: -6px
}

.sl--top-2\.5 {
  top: -10px
}

.sl--right-2\.5 {
  right: -10px
}

.sl--bottom-2\.5 {
  bottom: -10px
}

.sl--left-2\.5 {
  left: -10px
}

.sl--top-3\.5 {
  top: -14px
}

.sl--right-3\.5 {
  right: -14px
}

.sl--bottom-3\.5 {
  bottom: -14px
}

.sl--left-3\.5 {
  left: -14px
}

.sl-justify-start {
  justify-content: flex-start
}

.sl-justify-end {
  justify-content: flex-end
}

.sl-justify-center {
  justify-content: center
}

.sl-justify-between {
  justify-content: space-between
}

.sl-justify-around {
  justify-content: space-around
}

.sl-justify-evenly {
  justify-content: space-evenly
}

.sl-justify-items-start {
  justify-items: start
}

.sl-justify-items-end {
  justify-items: end
}

.sl-justify-items-center {
  justify-items: center
}

.sl-justify-items-stretch {
  justify-items: stretch
}

.sl-justify-self-auto {
  justify-self: auto
}

.sl-justify-self-start {
  justify-self: start
}

.sl-justify-self-end {
  justify-self: end
}

.sl-justify-self-center {
  justify-self: center
}

.sl-justify-self-stretch {
  justify-self: stretch
}

.sl-tracking-tight {
  letter-spacing: -.025em
}

.sl-tracking-normal {
  letter-spacing: 0
}

.sl-tracking-wide {
  letter-spacing: .025em
}

.sl-leading-none {
  line-height: 1
}

.sl-leading-tight {
  line-height: 1.2
}

.sl-leading-snug {
  line-height: 1.375
}

.sl-leading-normal {
  line-height: 1.5
}

.sl-leading-relaxed {
  line-height: 1.625
}

.sl-leading-loose {
  line-height: 2
}

.sl-leading-paragraph-leading {
  line-height: var(--lh-paragraph-leading)
}

.sl-leading-paragraph {
  line-height: var(--lh-paragraph)
}

.sl-leading-paragraph-small {
  line-height: var(--lh-paragraph-small)
}

.sl-leading-paragraph-tiny {
  line-height: var(--lh-paragraph-tiny)
}

.sl-m-0 {
  margin: 0
}

.sl-m-1 {
  margin: 4px
}

.sl-m-2 {
  margin: 8px
}

.sl-m-3 {
  margin: 12px
}

.sl-m-4 {
  margin: 16px
}

.sl-m-5 {
  margin: 20px
}

.sl-m-6 {
  margin: 24px
}

.sl-m-7 {
  margin: 28px
}

.sl-m-8 {
  margin: 32px
}

.sl-m-9 {
  margin: 36px
}

.sl-m-10 {
  margin: 40px
}

.sl-m-11 {
  margin: 44px
}

.sl-m-12 {
  margin: 48px
}

.sl-m-14 {
  margin: 56px
}

.sl-m-16 {
  margin: 64px
}

.sl-m-20 {
  margin: 80px
}

.sl-m-24 {
  margin: 96px
}

.sl-m-32 {
  margin: 128px
}

.sl-m-40 {
  margin: 160px
}

.sl-m-60 {
  margin: 240px
}

.sl-m-80 {
  margin: 320px
}

.sl-m-auto {
  margin: auto
}

.sl-m-px {
  margin: 1px
}

.sl-m-0\.5 {
  margin: 2px
}

.sl-m-1\.5 {
  margin: 6px
}

.sl-m-2\.5 {
  margin: 10px
}

.sl-m-3\.5 {
  margin: 14px
}

.sl--m-0 {
  margin: 0
}

.sl--m-1 {
  margin: -4px
}

.sl--m-2 {
  margin: -8px
}

.sl--m-3 {
  margin: -12px
}

.sl--m-4 {
  margin: -16px
}

.sl--m-5 {
  margin: -20px
}

.sl--m-6 {
  margin: -24px
}

.sl--m-7 {
  margin: -28px
}

.sl--m-8 {
  margin: -32px
}

.sl--m-9 {
  margin: -36px
}

.sl--m-10 {
  margin: -40px
}

.sl--m-11 {
  margin: -44px
}

.sl--m-12 {
  margin: -48px
}

.sl--m-14 {
  margin: -56px
}

.sl--m-16 {
  margin: -64px
}

.sl--m-20 {
  margin: -80px
}

.sl--m-24 {
  margin: -96px
}

.sl--m-32 {
  margin: -128px
}

.sl--m-40 {
  margin: -160px
}

.sl--m-60 {
  margin: -240px
}

.sl--m-80 {
  margin: -320px
}

.sl--m-px {
  margin: -1px
}

.sl--m-0\.5 {
  margin: -2px
}

.sl--m-1\.5 {
  margin: -6px
}

.sl--m-2\.5 {
  margin: -10px
}

.sl--m-3\.5 {
  margin: -14px
}

.sl-my-0 {
  margin-bottom: 0;
  margin-top: 0
}

.sl-mx-0 {
  margin-left: 0;
  margin-right: 0
}

.sl-my-1 {
  margin-bottom: 4px;
  margin-top: 4px
}

.sl-mx-1 {
  margin-left: 4px;
  margin-right: 4px
}

.sl-my-2 {
  margin-bottom: 8px;
  margin-top: 8px
}

.sl-mx-2 {
  margin-left: 8px;
  margin-right: 8px
}

.sl-my-3 {
  margin-bottom: 12px;
  margin-top: 12px
}

.sl-mx-3 {
  margin-left: 12px;
  margin-right: 12px
}

.sl-my-4 {
  margin-bottom: 16px;
  margin-top: 16px
}

.sl-mx-4 {
  margin-left: 16px;
  margin-right: 16px
}

.sl-my-5 {
  margin-bottom: 20px;
  margin-top: 20px
}

.sl-mx-5 {
  margin-left: 20px;
  margin-right: 20px
}

.sl-my-6 {
  margin-bottom: 24px;
  margin-top: 24px
}

.sl-mx-6 {
  margin-left: 24px;
  margin-right: 24px
}

.sl-my-7 {
  margin-bottom: 28px;
  margin-top: 28px
}

.sl-mx-7 {
  margin-left: 28px;
  margin-right: 28px
}

.sl-my-8 {
  margin-bottom: 32px;
  margin-top: 32px
}

.sl-mx-8 {
  margin-left: 32px;
  margin-right: 32px
}

.sl-my-9 {
  margin-bottom: 36px;
  margin-top: 36px
}

.sl-mx-9 {
  margin-left: 36px;
  margin-right: 36px
}

.sl-my-10 {
  margin-bottom: 40px;
  margin-top: 40px
}

.sl-mx-10 {
  margin-left: 40px;
  margin-right: 40px
}

.sl-my-11 {
  margin-bottom: 44px;
  margin-top: 44px
}

.sl-mx-11 {
  margin-left: 44px;
  margin-right: 44px
}

.sl-my-12 {
  margin-bottom: 48px;
  margin-top: 48px
}

.sl-mx-12 {
  margin-left: 48px;
  margin-right: 48px
}

.sl-my-14 {
  margin-bottom: 56px;
  margin-top: 56px
}

.sl-mx-14 {
  margin-left: 56px;
  margin-right: 56px
}

.sl-my-16 {
  margin-bottom: 64px;
  margin-top: 64px
}

.sl-mx-16 {
  margin-left: 64px;
  margin-right: 64px
}

.sl-my-20 {
  margin-bottom: 80px;
  margin-top: 80px
}

.sl-mx-20 {
  margin-left: 80px;
  margin-right: 80px
}

.sl-my-24 {
  margin-bottom: 96px;
  margin-top: 96px
}

.sl-mx-24 {
  margin-left: 96px;
  margin-right: 96px
}

.sl-my-32 {
  margin-bottom: 128px;
  margin-top: 128px
}

.sl-mx-32 {
  margin-left: 128px;
  margin-right: 128px
}

.sl-my-40 {
  margin-bottom: 160px;
  margin-top: 160px
}

.sl-mx-40 {
  margin-left: 160px;
  margin-right: 160px
}

.sl-my-60 {
  margin-bottom: 240px;
  margin-top: 240px
}

.sl-mx-60 {
  margin-left: 240px;
  margin-right: 240px
}

.sl-my-80 {
  margin-bottom: 320px;
  margin-top: 320px
}

.sl-mx-80 {
  margin-left: 320px;
  margin-right: 320px
}

.sl-my-auto {
  margin-bottom: auto;
  margin-top: auto
}

.sl-mx-auto {
  margin-left: auto;
  margin-right: auto
}

.sl-my-px {
  margin-bottom: 1px;
  margin-top: 1px
}

.sl-mx-px {
  margin-left: 1px;
  margin-right: 1px
}

.sl-my-0\.5 {
  margin-bottom: 2px;
  margin-top: 2px
}

.sl-mx-0\.5 {
  margin-left: 2px;
  margin-right: 2px
}

.sl-my-1\.5 {
  margin-bottom: 6px;
  margin-top: 6px
}

.sl-mx-1\.5 {
  margin-left: 6px;
  margin-right: 6px
}

.sl-my-2\.5 {
  margin-bottom: 10px;
  margin-top: 10px
}

.sl-mx-2\.5 {
  margin-left: 10px;
  margin-right: 10px
}

.sl-my-3\.5 {
  margin-bottom: 14px;
  margin-top: 14px
}

.sl-mx-3\.5 {
  margin-left: 14px;
  margin-right: 14px
}

.sl--my-0 {
  margin-bottom: 0;
  margin-top: 0
}

.sl--mx-0 {
  margin-left: 0;
  margin-right: 0
}

.sl--my-1 {
  margin-bottom: -4px;
  margin-top: -4px
}

.sl--mx-1 {
  margin-left: -4px;
  margin-right: -4px
}

.sl--my-2 {
  margin-bottom: -8px;
  margin-top: -8px
}

.sl--mx-2 {
  margin-left: -8px;
  margin-right: -8px
}

.sl--my-3 {
  margin-bottom: -12px;
  margin-top: -12px
}

.sl--mx-3 {
  margin-left: -12px;
  margin-right: -12px
}

.sl--my-4 {
  margin-bottom: -16px;
  margin-top: -16px
}

.sl--mx-4 {
  margin-left: -16px;
  margin-right: -16px
}

.sl--my-5 {
  margin-bottom: -20px;
  margin-top: -20px
}

.sl--mx-5 {
  margin-left: -20px;
  margin-right: -20px
}

.sl--my-6 {
  margin-bottom: -24px;
  margin-top: -24px
}

.sl--mx-6 {
  margin-left: -24px;
  margin-right: -24px
}

.sl--my-7 {
  margin-bottom: -28px;
  margin-top: -28px
}

.sl--mx-7 {
  margin-left: -28px;
  margin-right: -28px
}

.sl--my-8 {
  margin-bottom: -32px;
  margin-top: -32px
}

.sl--mx-8 {
  margin-left: -32px;
  margin-right: -32px
}

.sl--my-9 {
  margin-bottom: -36px;
  margin-top: -36px
}

.sl--mx-9 {
  margin-left: -36px;
  margin-right: -36px
}

.sl--my-10 {
  margin-bottom: -40px;
  margin-top: -40px
}

.sl--mx-10 {
  margin-left: -40px;
  margin-right: -40px
}

.sl--my-11 {
  margin-bottom: -44px;
  margin-top: -44px
}

.sl--mx-11 {
  margin-left: -44px;
  margin-right: -44px
}

.sl--my-12 {
  margin-bottom: -48px;
  margin-top: -48px
}

.sl--mx-12 {
  margin-left: -48px;
  margin-right: -48px
}

.sl--my-14 {
  margin-bottom: -56px;
  margin-top: -56px
}

.sl--mx-14 {
  margin-left: -56px;
  margin-right: -56px
}

.sl--my-16 {
  margin-bottom: -64px;
  margin-top: -64px
}

.sl--mx-16 {
  margin-left: -64px;
  margin-right: -64px
}

.sl--my-20 {
  margin-bottom: -80px;
  margin-top: -80px
}

.sl--mx-20 {
  margin-left: -80px;
  margin-right: -80px
}

.sl--my-24 {
  margin-bottom: -96px;
  margin-top: -96px
}

.sl--mx-24 {
  margin-left: -96px;
  margin-right: -96px
}

.sl--my-32 {
  margin-bottom: -128px;
  margin-top: -128px
}

.sl--mx-32 {
  margin-left: -128px;
  margin-right: -128px
}

.sl--my-40 {
  margin-bottom: -160px;
  margin-top: -160px
}

.sl--mx-40 {
  margin-left: -160px;
  margin-right: -160px
}

.sl--my-60 {
  margin-bottom: -240px;
  margin-top: -240px
}

.sl--mx-60 {
  margin-left: -240px;
  margin-right: -240px
}

.sl--my-80 {
  margin-bottom: -320px;
  margin-top: -320px
}

.sl--mx-80 {
  margin-left: -320px;
  margin-right: -320px
}

.sl--my-px {
  margin-bottom: -1px;
  margin-top: -1px
}

.sl--mx-px {
  margin-left: -1px;
  margin-right: -1px
}

.sl--my-0\.5 {
  margin-bottom: -2px;
  margin-top: -2px
}

.sl--mx-0\.5 {
  margin-left: -2px;
  margin-right: -2px
}

.sl--my-1\.5 {
  margin-bottom: -6px;
  margin-top: -6px
}

.sl--mx-1\.5 {
  margin-left: -6px;
  margin-right: -6px
}

.sl--my-2\.5 {
  margin-bottom: -10px;
  margin-top: -10px
}

.sl--mx-2\.5 {
  margin-left: -10px;
  margin-right: -10px
}

.sl--my-3\.5 {
  margin-bottom: -14px;
  margin-top: -14px
}

.sl--mx-3\.5 {
  margin-left: -14px;
  margin-right: -14px
}

.sl-mt-0 {
  margin-top: 0
}

.sl-mr-0 {
  margin-right: 0
}

.sl-mb-0 {
  margin-bottom: 0
}

.sl-ml-0 {
  margin-left: 0
}

.sl-mt-1 {
  margin-top: 4px
}

.sl-mr-1 {
  margin-right: 4px
}

.sl-mb-1 {
  margin-bottom: 4px
}

.sl-ml-1 {
  margin-left: 4px
}

.sl-mt-2 {
  margin-top: 8px
}

.sl-mr-2 {
  margin-right: 8px
}

.sl-mb-2 {
  margin-bottom: 8px
}

.sl-ml-2 {
  margin-left: 8px
}

.sl-mt-3 {
  margin-top: 12px
}

.sl-mr-3 {
  margin-right: 12px
}

.sl-mb-3 {
  margin-bottom: 12px
}

.sl-ml-3 {
  margin-left: 12px
}

.sl-mt-4 {
  margin-top: 16px
}

.sl-mr-4 {
  margin-right: 16px
}

.sl-mb-4 {
  margin-bottom: 16px
}

.sl-ml-4 {
  margin-left: 16px
}

.sl-mt-5 {
  margin-top: 20px
}

.sl-mr-5 {
  margin-right: 20px
}

.sl-mb-5 {
  margin-bottom: 20px
}

.sl-ml-5 {
  margin-left: 20px
}

.sl-mt-6 {
  margin-top: 24px
}

.sl-mr-6 {
  margin-right: 24px
}

.sl-mb-6 {
  margin-bottom: 24px
}

.sl-ml-6 {
  margin-left: 24px
}

.sl-mt-7 {
  margin-top: 28px
}

.sl-mr-7 {
  margin-right: 28px
}

.sl-mb-7 {
  margin-bottom: 28px
}

.sl-ml-7 {
  margin-left: 28px
}

.sl-mt-8 {
  margin-top: 32px
}

.sl-mr-8 {
  margin-right: 32px
}

.sl-mb-8 {
  margin-bottom: 32px
}

.sl-ml-8 {
  margin-left: 32px
}

.sl-mt-9 {
  margin-top: 36px
}

.sl-mr-9 {
  margin-right: 36px
}

.sl-mb-9 {
  margin-bottom: 36px
}

.sl-ml-9 {
  margin-left: 36px
}

.sl-mt-10 {
  margin-top: 40px
}

.sl-mr-10 {
  margin-right: 40px
}

.sl-mb-10 {
  margin-bottom: 40px
}

.sl-ml-10 {
  margin-left: 40px
}

.sl-mt-11 {
  margin-top: 44px
}

.sl-mr-11 {
  margin-right: 44px
}

.sl-mb-11 {
  margin-bottom: 44px
}

.sl-ml-11 {
  margin-left: 44px
}

.sl-mt-12 {
  margin-top: 48px
}

.sl-mr-12 {
  margin-right: 48px
}

.sl-mb-12 {
  margin-bottom: 48px
}

.sl-ml-12 {
  margin-left: 48px
}

.sl-mt-14 {
  margin-top: 56px
}

.sl-mr-14 {
  margin-right: 56px
}

.sl-mb-14 {
  margin-bottom: 56px
}

.sl-ml-14 {
  margin-left: 56px
}

.sl-mt-16 {
  margin-top: 64px
}

.sl-mr-16 {
  margin-right: 64px
}

.sl-mb-16 {
  margin-bottom: 64px
}

.sl-ml-16 {
  margin-left: 64px
}

.sl-mt-20 {
  margin-top: 80px
}

.sl-mr-20 {
  margin-right: 80px
}

.sl-mb-20 {
  margin-bottom: 80px
}

.sl-ml-20 {
  margin-left: 80px
}

.sl-mt-24 {
  margin-top: 96px
}

.sl-mr-24 {
  margin-right: 96px
}

.sl-mb-24 {
  margin-bottom: 96px
}

.sl-ml-24 {
  margin-left: 96px
}

.sl-mt-32 {
  margin-top: 128px
}

.sl-mr-32 {
  margin-right: 128px
}

.sl-mb-32 {
  margin-bottom: 128px
}

.sl-ml-32 {
  margin-left: 128px
}

.sl-mt-40 {
  margin-top: 160px
}

.sl-mr-40 {
  margin-right: 160px
}

.sl-mb-40 {
  margin-bottom: 160px
}

.sl-ml-40 {
  margin-left: 160px
}

.sl-mt-60 {
  margin-top: 240px
}

.sl-mr-60 {
  margin-right: 240px
}

.sl-mb-60 {
  margin-bottom: 240px
}

.sl-ml-60 {
  margin-left: 240px
}

.sl-mt-80 {
  margin-top: 320px
}

.sl-mr-80 {
  margin-right: 320px
}

.sl-mb-80 {
  margin-bottom: 320px
}

.sl-ml-80 {
  margin-left: 320px
}

.sl-mt-auto {
  margin-top: auto
}

.sl-mr-auto {
  margin-right: auto
}

.sl-mb-auto {
  margin-bottom: auto
}

.sl-ml-auto {
  margin-left: auto
}

.sl-mt-px {
  margin-top: 1px
}

.sl-mr-px {
  margin-right: 1px
}

.sl-mb-px {
  margin-bottom: 1px
}

.sl-ml-px {
  margin-left: 1px
}

.sl-mt-0\.5 {
  margin-top: 2px
}

.sl-mr-0\.5 {
  margin-right: 2px
}

.sl-mb-0\.5 {
  margin-bottom: 2px
}

.sl-ml-0\.5 {
  margin-left: 2px
}

.sl-mt-1\.5 {
  margin-top: 6px
}

.sl-mr-1\.5 {
  margin-right: 6px
}

.sl-mb-1\.5 {
  margin-bottom: 6px
}

.sl-ml-1\.5 {
  margin-left: 6px
}

.sl-mt-2\.5 {
  margin-top: 10px
}

.sl-mr-2\.5 {
  margin-right: 10px
}

.sl-mb-2\.5 {
  margin-bottom: 10px
}

.sl-ml-2\.5 {
  margin-left: 10px
}

.sl-mt-3\.5 {
  margin-top: 14px
}

.sl-mr-3\.5 {
  margin-right: 14px
}

.sl-mb-3\.5 {
  margin-bottom: 14px
}

.sl-ml-3\.5 {
  margin-left: 14px
}

.sl--mt-0 {
  margin-top: 0
}

.sl--mr-0 {
  margin-right: 0
}

.sl--mb-0 {
  margin-bottom: 0
}

.sl--ml-0 {
  margin-left: 0
}

.sl--mt-1 {
  margin-top: -4px
}

.sl--mr-1 {
  margin-right: -4px
}

.sl--mb-1 {
  margin-bottom: -4px
}

.sl--ml-1 {
  margin-left: -4px
}

.sl--mt-2 {
  margin-top: -8px
}

.sl--mr-2 {
  margin-right: -8px
}

.sl--mb-2 {
  margin-bottom: -8px
}

.sl--ml-2 {
  margin-left: -8px
}

.sl--mt-3 {
  margin-top: -12px
}

.sl--mr-3 {
  margin-right: -12px
}

.sl--mb-3 {
  margin-bottom: -12px
}

.sl--ml-3 {
  margin-left: -12px
}

.sl--mt-4 {
  margin-top: -16px
}

.sl--mr-4 {
  margin-right: -16px
}

.sl--mb-4 {
  margin-bottom: -16px
}

.sl--ml-4 {
  margin-left: -16px
}

.sl--mt-5 {
  margin-top: -20px
}

.sl--mr-5 {
  margin-right: -20px
}

.sl--mb-5 {
  margin-bottom: -20px
}

.sl--ml-5 {
  margin-left: -20px
}

.sl--mt-6 {
  margin-top: -24px
}

.sl--mr-6 {
  margin-right: -24px
}

.sl--mb-6 {
  margin-bottom: -24px
}

.sl--ml-6 {
  margin-left: -24px
}

.sl--mt-7 {
  margin-top: -28px
}

.sl--mr-7 {
  margin-right: -28px
}

.sl--mb-7 {
  margin-bottom: -28px
}

.sl--ml-7 {
  margin-left: -28px
}

.sl--mt-8 {
  margin-top: -32px
}

.sl--mr-8 {
  margin-right: -32px
}

.sl--mb-8 {
  margin-bottom: -32px
}

.sl--ml-8 {
  margin-left: -32px
}

.sl--mt-9 {
  margin-top: -36px
}

.sl--mr-9 {
  margin-right: -36px
}

.sl--mb-9 {
  margin-bottom: -36px
}

.sl--ml-9 {
  margin-left: -36px
}

.sl--mt-10 {
  margin-top: -40px
}

.sl--mr-10 {
  margin-right: -40px
}

.sl--mb-10 {
  margin-bottom: -40px
}

.sl--ml-10 {
  margin-left: -40px
}

.sl--mt-11 {
  margin-top: -44px
}

.sl--mr-11 {
  margin-right: -44px
}

.sl--mb-11 {
  margin-bottom: -44px
}

.sl--ml-11 {
  margin-left: -44px
}

.sl--mt-12 {
  margin-top: -48px
}

.sl--mr-12 {
  margin-right: -48px
}

.sl--mb-12 {
  margin-bottom: -48px
}

.sl--ml-12 {
  margin-left: -48px
}

.sl--mt-14 {
  margin-top: -56px
}

.sl--mr-14 {
  margin-right: -56px
}

.sl--mb-14 {
  margin-bottom: -56px
}

.sl--ml-14 {
  margin-left: -56px
}

.sl--mt-16 {
  margin-top: -64px
}

.sl--mr-16 {
  margin-right: -64px
}

.sl--mb-16 {
  margin-bottom: -64px
}

.sl--ml-16 {
  margin-left: -64px
}

.sl--mt-20 {
  margin-top: -80px
}

.sl--mr-20 {
  margin-right: -80px
}

.sl--mb-20 {
  margin-bottom: -80px
}

.sl--ml-20 {
  margin-left: -80px
}

.sl--mt-24 {
  margin-top: -96px
}

.sl--mr-24 {
  margin-right: -96px
}

.sl--mb-24 {
  margin-bottom: -96px
}

.sl--ml-24 {
  margin-left: -96px
}

.sl--mt-32 {
  margin-top: -128px
}

.sl--mr-32 {
  margin-right: -128px
}

.sl--mb-32 {
  margin-bottom: -128px
}

.sl--ml-32 {
  margin-left: -128px
}

.sl--mt-40 {
  margin-top: -160px
}

.sl--mr-40 {
  margin-right: -160px
}

.sl--mb-40 {
  margin-bottom: -160px
}

.sl--ml-40 {
  margin-left: -160px
}

.sl--mt-60 {
  margin-top: -240px
}

.sl--mr-60 {
  margin-right: -240px
}

.sl--mb-60 {
  margin-bottom: -240px
}

.sl--ml-60 {
  margin-left: -240px
}

.sl--mt-80 {
  margin-top: -320px
}

.sl--mr-80 {
  margin-right: -320px
}

.sl--mb-80 {
  margin-bottom: -320px
}

.sl--ml-80 {
  margin-left: -320px
}

.sl--mt-px {
  margin-top: -1px
}

.sl--mr-px {
  margin-right: -1px
}

.sl--mb-px {
  margin-bottom: -1px
}

.sl--ml-px {
  margin-left: -1px
}

.sl--mt-0\.5 {
  margin-top: -2px
}

.sl--mr-0\.5 {
  margin-right: -2px
}

.sl--mb-0\.5 {
  margin-bottom: -2px
}

.sl--ml-0\.5 {
  margin-left: -2px
}

.sl--mt-1\.5 {
  margin-top: -6px
}

.sl--mr-1\.5 {
  margin-right: -6px
}

.sl--mb-1\.5 {
  margin-bottom: -6px
}

.sl--ml-1\.5 {
  margin-left: -6px
}

.sl--mt-2\.5 {
  margin-top: -10px
}

.sl--mr-2\.5 {
  margin-right: -10px
}

.sl--mb-2\.5 {
  margin-bottom: -10px
}

.sl--ml-2\.5 {
  margin-left: -10px
}

.sl--mt-3\.5 {
  margin-top: -14px
}

.sl--mr-3\.5 {
  margin-right: -14px
}

.sl--mb-3\.5 {
  margin-bottom: -14px
}

.sl--ml-3\.5 {
  margin-left: -14px
}

.sl-max-h-full {
  max-height: 100%
}

.sl-max-h-screen {
  max-height: 100vh
}

.sl-max-w-none {
  max-width: none
}

.sl-max-w-full {
  max-width: 100%
}

.sl-max-w-min {
  max-width: -moz-min-content;
  max-width: min-content
}

.sl-max-w-max {
  max-width: -moz-max-content;
  max-width: max-content
}

.sl-max-w-prose {
  max-width: 65ch
}

.sl-min-h-full {
  min-height: 100%
}

.sl-min-h-screen {
  min-height: 100vh
}

.sl-min-w-full {
  min-width: 100%
}

.sl-min-w-min {
  min-width: -moz-min-content;
  min-width: min-content
}

.sl-min-w-max {
  min-width: -moz-max-content;
  min-width: max-content
}

.sl-object-contain {
  object-fit: contain
}

.sl-object-cover {
  object-fit: cover
}

.sl-object-fill {
  object-fit: fill
}

.sl-object-none {
  object-fit: none
}

.sl-object-scale-down {
  object-fit: scale-down
}

.sl-object-bottom {
  object-position: bottom
}

.sl-object-center {
  object-position: center
}

.sl-object-left {
  object-position: left
}

.sl-object-left-bottom {
  object-position: left bottom
}

.sl-object-left-top {
  object-position: left top
}

.sl-object-right {
  object-position: right
}

.sl-object-right-bottom {
  object-position: right bottom
}

.sl-object-right-top {
  object-position: right top
}

.sl-object-top {
  object-position: top
}

.sl-opacity-0 {
  opacity: 0
}

.sl-opacity-5 {
  opacity: .05
}

.sl-opacity-10 {
  opacity: .1
}

.sl-opacity-20 {
  opacity: .2
}

.sl-opacity-30 {
  opacity: .3
}

.sl-opacity-40 {
  opacity: .4
}

.sl-opacity-50 {
  opacity: .5
}

.sl-opacity-60 {
  opacity: .6
}

.sl-opacity-70 {
  opacity: .7
}

.sl-opacity-90 {
  opacity: .9
}

.sl-opacity-100 {
  opacity: 1
}

.hover\:sl-opacity-0:hover {
  opacity: 0
}

.hover\:sl-opacity-5:hover {
  opacity: .05
}

.hover\:sl-opacity-10:hover {
  opacity: .1
}

.hover\:sl-opacity-20:hover {
  opacity: .2
}

.hover\:sl-opacity-30:hover {
  opacity: .3
}

.hover\:sl-opacity-40:hover {
  opacity: .4
}

.hover\:sl-opacity-50:hover {
  opacity: .5
}

.hover\:sl-opacity-60:hover {
  opacity: .6
}

.hover\:sl-opacity-70:hover {
  opacity: .7
}

.hover\:sl-opacity-90:hover {
  opacity: .9
}

.hover\:sl-opacity-100:hover {
  opacity: 1
}

.focus\:sl-opacity-0:focus {
  opacity: 0
}

.focus\:sl-opacity-5:focus {
  opacity: .05
}

.focus\:sl-opacity-10:focus {
  opacity: .1
}

.focus\:sl-opacity-20:focus {
  opacity: .2
}

.focus\:sl-opacity-30:focus {
  opacity: .3
}

.focus\:sl-opacity-40:focus {
  opacity: .4
}

.focus\:sl-opacity-50:focus {
  opacity: .5
}

.focus\:sl-opacity-60:focus {
  opacity: .6
}

.focus\:sl-opacity-70:focus {
  opacity: .7
}

.focus\:sl-opacity-90:focus {
  opacity: .9
}

.focus\:sl-opacity-100:focus {
  opacity: 1
}

.active\:sl-opacity-0:active {
  opacity: 0
}

.active\:sl-opacity-5:active {
  opacity: .05
}

.active\:sl-opacity-10:active {
  opacity: .1
}

.active\:sl-opacity-20:active {
  opacity: .2
}

.active\:sl-opacity-30:active {
  opacity: .3
}

.active\:sl-opacity-40:active {
  opacity: .4
}

.active\:sl-opacity-50:active {
  opacity: .5
}

.active\:sl-opacity-60:active {
  opacity: .6
}

.active\:sl-opacity-70:active {
  opacity: .7
}

.active\:sl-opacity-90:active {
  opacity: .9
}

.active\:sl-opacity-100:active {
  opacity: 1
}

.disabled\:sl-opacity-0:disabled {
  opacity: 0
}

.disabled\:sl-opacity-5:disabled {
  opacity: .05
}

.disabled\:sl-opacity-10:disabled {
  opacity: .1
}

.disabled\:sl-opacity-20:disabled {
  opacity: .2
}

.disabled\:sl-opacity-30:disabled {
  opacity: .3
}

.disabled\:sl-opacity-40:disabled {
  opacity: .4
}

.disabled\:sl-opacity-50:disabled {
  opacity: .5
}

.disabled\:sl-opacity-60:disabled {
  opacity: .6
}

.disabled\:sl-opacity-70:disabled {
  opacity: .7
}

.disabled\:sl-opacity-90:disabled {
  opacity: .9
}

.disabled\:sl-opacity-100:disabled {
  opacity: 1
}

.sl-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.sl-overflow-auto {
  overflow: auto
}

.sl-overflow-hidden {
  overflow: hidden
}

.sl-overflow-visible {
  overflow: visible
}

.sl-overflow-scroll {
  overflow: scroll
}

.sl-overflow-x-auto {
  overflow-x: auto
}

.sl-overflow-y-auto {
  overflow-y: auto
}

.sl-overflow-x-hidden {
  overflow-x: hidden
}

.sl-overflow-y-hidden {
  overflow-y: hidden
}

.sl-overflow-x-visible {
  overflow-x: visible
}

.sl-overflow-y-visible {
  overflow-y: visible
}

.sl-overflow-x-scroll {
  overflow-x: scroll
}

.sl-overflow-y-scroll {
  overflow-y: scroll
}

.sl-overscroll-auto {
  overscroll-behavior: auto
}

.sl-overscroll-contain {
  overscroll-behavior: contain
}

.sl-overscroll-none {
  overscroll-behavior: none
}

.sl-overscroll-y-auto {
  overscroll-behavior-y: auto
}

.sl-overscroll-y-contain {
  overscroll-behavior-y: contain
}

.sl-overscroll-y-none {
  overscroll-behavior-y: none
}

.sl-overscroll-x-auto {
  overscroll-behavior-x: auto
}

.sl-overscroll-x-contain {
  overscroll-behavior-x: contain
}

.sl-overscroll-x-none {
  overscroll-behavior-x: none
}

.sl-p-0 {
  padding: 0
}

.sl-p-1 {
  padding: 4px
}

.sl-p-2 {
  padding: 8px
}

.sl-p-3 {
  padding: 12px
}

.sl-p-4 {
  padding: 16px
}

.sl-p-5 {
  padding: 20px
}

.sl-p-6 {
  padding: 24px
}

.sl-p-7 {
  padding: 28px
}

.sl-p-8 {
  padding: 32px
}

.sl-p-9 {
  padding: 36px
}

.sl-p-10 {
  padding: 40px
}

.sl-p-11 {
  padding: 44px
}

.sl-p-12 {
  padding: 48px
}

.sl-p-14 {
  padding: 56px
}

.sl-p-16 {
  padding: 64px
}

.sl-p-20 {
  padding: 80px
}

.sl-p-24 {
  padding: 96px
}

.sl-p-32 {
  padding: 128px
}

.sl-p-40 {
  padding: 160px
}

.sl-p-60 {
  padding: 240px
}

.sl-p-80 {
  padding: 320px
}

.sl-p-px {
  padding: 1px
}

.sl-p-0\.5 {
  padding: 2px
}

.sl-p-1\.5 {
  padding: 6px
}

.sl-p-2\.5 {
  padding: 10px
}

.sl-p-3\.5 {
  padding: 14px
}

.sl-py-0 {
  padding-bottom: 0;
  padding-top: 0
}

.sl-px-0 {
  padding-left: 0;
  padding-right: 0
}

.sl-py-1 {
  padding-bottom: 4px;
  padding-top: 4px
}

.sl-px-1 {
  padding-left: 4px;
  padding-right: 4px
}

.sl-py-2 {
  padding-bottom: 8px;
  padding-top: 8px
}

.sl-px-2 {
  padding-left: 8px;
  padding-right: 8px
}

.sl-py-3 {
  padding-bottom: 12px;
  padding-top: 12px
}

.sl-px-3 {
  padding-left: 12px;
  padding-right: 12px
}

.sl-py-4 {
  padding-bottom: 16px;
  padding-top: 16px
}

.sl-px-4 {
  padding-left: 16px;
  padding-right: 16px
}

.sl-py-5 {
  padding-bottom: 20px;
  padding-top: 20px
}

.sl-px-5 {
  padding-left: 20px;
  padding-right: 20px
}

.sl-py-6 {
  padding-bottom: 24px;
  padding-top: 24px
}

.sl-px-6 {
  padding-left: 24px;
  padding-right: 24px
}

.sl-py-7 {
  padding-bottom: 28px;
  padding-top: 28px
}

.sl-px-7 {
  padding-left: 28px;
  padding-right: 28px
}

.sl-py-8 {
  padding-bottom: 32px;
  padding-top: 32px
}

.sl-px-8 {
  padding-left: 32px;
  padding-right: 32px
}

.sl-py-9 {
  padding-bottom: 36px;
  padding-top: 36px
}

.sl-px-9 {
  padding-left: 36px;
  padding-right: 36px
}

.sl-py-10 {
  padding-bottom: 40px;
  padding-top: 40px
}

.sl-px-10 {
  padding-left: 40px;
  padding-right: 40px
}

.sl-py-11 {
  padding-bottom: 44px;
  padding-top: 44px
}

.sl-px-11 {
  padding-left: 44px;
  padding-right: 44px
}

.sl-py-12 {
  padding-bottom: 48px;
  padding-top: 48px
}

.sl-px-12 {
  padding-left: 48px;
  padding-right: 48px
}

.sl-py-14 {
  padding-bottom: 56px;
  padding-top: 56px
}

.sl-px-14 {
  padding-left: 56px;
  padding-right: 56px
}

.sl-py-16 {
  padding-bottom: 64px;
  padding-top: 64px
}

.sl-px-16 {
  padding-left: 64px;
  padding-right: 64px
}

.sl-py-20 {
  padding-bottom: 80px;
  padding-top: 80px
}

.sl-px-20 {
  padding-left: 80px;
  padding-right: 80px
}

.sl-py-24 {
  padding-bottom: 96px;
  padding-top: 96px
}

.sl-px-24 {
  padding-left: 96px;
  padding-right: 96px
}

.sl-py-32 {
  padding-bottom: 128px;
  padding-top: 128px
}

.sl-px-32 {
  padding-left: 128px;
  padding-right: 128px
}

.sl-py-40 {
  padding-bottom: 160px;
  padding-top: 160px
}

.sl-px-40 {
  padding-left: 160px;
  padding-right: 160px
}

.sl-py-60 {
  padding-bottom: 240px;
  padding-top: 240px
}

.sl-px-60 {
  padding-left: 240px;
  padding-right: 240px
}

.sl-py-80 {
  padding-bottom: 320px;
  padding-top: 320px
}

.sl-px-80 {
  padding-left: 320px;
  padding-right: 320px
}

.sl-py-px {
  padding-bottom: 1px;
  padding-top: 1px
}

.sl-px-px {
  padding-left: 1px;
  padding-right: 1px
}

.sl-py-0\.5 {
  padding-bottom: 2px;
  padding-top: 2px
}

.sl-px-0\.5 {
  padding-left: 2px;
  padding-right: 2px
}

.sl-py-1\.5 {
  padding-bottom: 6px;
  padding-top: 6px
}

.sl-px-1\.5 {
  padding-left: 6px;
  padding-right: 6px
}

.sl-py-2\.5 {
  padding-bottom: 10px;
  padding-top: 10px
}

.sl-px-2\.5 {
  padding-left: 10px;
  padding-right: 10px
}

.sl-py-3\.5 {
  padding-bottom: 14px;
  padding-top: 14px
}

.sl-px-3\.5 {
  padding-left: 14px;
  padding-right: 14px
}

.sl-pt-0 {
  padding-top: 0
}

.sl-pr-0 {
  padding-right: 0
}

.sl-pb-0 {
  padding-bottom: 0
}

.sl-pl-0 {
  padding-left: 0
}

.sl-pt-1 {
  padding-top: 4px
}

.sl-pr-1 {
  padding-right: 4px
}

.sl-pb-1 {
  padding-bottom: 4px
}

.sl-pl-1 {
  padding-left: 4px
}

.sl-pt-2 {
  padding-top: 8px
}

.sl-pr-2 {
  padding-right: 8px
}

.sl-pb-2 {
  padding-bottom: 8px
}

.sl-pl-2 {
  padding-left: 8px
}

.sl-pt-3 {
  padding-top: 12px
}

.sl-pr-3 {
  padding-right: 12px
}

.sl-pb-3 {
  padding-bottom: 12px
}

.sl-pl-3 {
  padding-left: 12px
}

.sl-pt-4 {
  padding-top: 16px
}

.sl-pr-4 {
  padding-right: 16px
}

.sl-pb-4 {
  padding-bottom: 16px
}

.sl-pl-4 {
  padding-left: 16px
}

.sl-pt-5 {
  padding-top: 20px
}

.sl-pr-5 {
  padding-right: 20px
}

.sl-pb-5 {
  padding-bottom: 20px
}

.sl-pl-5 {
  padding-left: 20px
}

.sl-pt-6 {
  padding-top: 24px
}

.sl-pr-6 {
  padding-right: 24px
}

.sl-pb-6 {
  padding-bottom: 24px
}

.sl-pl-6 {
  padding-left: 24px
}

.sl-pt-7 {
  padding-top: 28px
}

.sl-pr-7 {
  padding-right: 28px
}

.sl-pb-7 {
  padding-bottom: 28px
}

.sl-pl-7 {
  padding-left: 28px
}

.sl-pt-8 {
  padding-top: 32px
}

.sl-pr-8 {
  padding-right: 32px
}

.sl-pb-8 {
  padding-bottom: 32px
}

.sl-pl-8 {
  padding-left: 32px
}

.sl-pt-9 {
  padding-top: 36px
}

.sl-pr-9 {
  padding-right: 36px
}

.sl-pb-9 {
  padding-bottom: 36px
}

.sl-pl-9 {
  padding-left: 36px
}

.sl-pt-10 {
  padding-top: 40px
}

.sl-pr-10 {
  padding-right: 40px
}

.sl-pb-10 {
  padding-bottom: 40px
}

.sl-pl-10 {
  padding-left: 40px
}

.sl-pt-11 {
  padding-top: 44px
}

.sl-pr-11 {
  padding-right: 44px
}

.sl-pb-11 {
  padding-bottom: 44px
}

.sl-pl-11 {
  padding-left: 44px
}

.sl-pt-12 {
  padding-top: 48px
}

.sl-pr-12 {
  padding-right: 48px
}

.sl-pb-12 {
  padding-bottom: 48px
}

.sl-pl-12 {
  padding-left: 48px
}

.sl-pt-14 {
  padding-top: 56px
}

.sl-pr-14 {
  padding-right: 56px
}

.sl-pb-14 {
  padding-bottom: 56px
}

.sl-pl-14 {
  padding-left: 56px
}

.sl-pt-16 {
  padding-top: 64px
}

.sl-pr-16 {
  padding-right: 64px
}

.sl-pb-16 {
  padding-bottom: 64px
}

.sl-pl-16 {
  padding-left: 64px
}

.sl-pt-20 {
  padding-top: 80px
}

.sl-pr-20 {
  padding-right: 80px
}

.sl-pb-20 {
  padding-bottom: 80px
}

.sl-pl-20 {
  padding-left: 80px
}

.sl-pt-24 {
  padding-top: 96px
}

.sl-pr-24 {
  padding-right: 96px
}

.sl-pb-24 {
  padding-bottom: 96px
}

.sl-pl-24 {
  padding-left: 96px
}

.sl-pt-32 {
  padding-top: 128px
}

.sl-pr-32 {
  padding-right: 128px
}

.sl-pb-32 {
  padding-bottom: 128px
}

.sl-pl-32 {
  padding-left: 128px
}

.sl-pt-40 {
  padding-top: 160px
}

.sl-pr-40 {
  padding-right: 160px
}

.sl-pb-40 {
  padding-bottom: 160px
}

.sl-pl-40 {
  padding-left: 160px
}

.sl-pt-60 {
  padding-top: 240px
}

.sl-pr-60 {
  padding-right: 240px
}

.sl-pb-60 {
  padding-bottom: 240px
}

.sl-pl-60 {
  padding-left: 240px
}

.sl-pt-80 {
  padding-top: 320px
}

.sl-pr-80 {
  padding-right: 320px
}

.sl-pb-80 {
  padding-bottom: 320px
}

.sl-pl-80 {
  padding-left: 320px
}

.sl-pt-px {
  padding-top: 1px
}

.sl-pr-px {
  padding-right: 1px
}

.sl-pb-px {
  padding-bottom: 1px
}

.sl-pl-px {
  padding-left: 1px
}

.sl-pt-0\.5 {
  padding-top: 2px
}

.sl-pr-0\.5 {
  padding-right: 2px
}

.sl-pb-0\.5 {
  padding-bottom: 2px
}

.sl-pl-0\.5 {
  padding-left: 2px
}

.sl-pt-1\.5 {
  padding-top: 6px
}

.sl-pr-1\.5 {
  padding-right: 6px
}

.sl-pb-1\.5 {
  padding-bottom: 6px
}

.sl-pl-1\.5 {
  padding-left: 6px
}

.sl-pt-2\.5 {
  padding-top: 10px
}

.sl-pr-2\.5 {
  padding-right: 10px
}

.sl-pb-2\.5 {
  padding-bottom: 10px
}

.sl-pl-2\.5 {
  padding-left: 10px
}

.sl-pt-3\.5 {
  padding-top: 14px
}

.sl-pr-3\.5 {
  padding-right: 14px
}

.sl-pb-3\.5 {
  padding-bottom: 14px
}

.sl-pl-3\.5 {
  padding-left: 14px
}

.sl-placeholder::-ms-input-placeholder {
  color: var(--color-text-light)
}

.sl-placeholder::placeholder {
  color: var(--color-text-light)
}

.sl-placeholder-primary::-ms-input-placeholder {
  color: #3898ff
}

.sl-placeholder-primary::placeholder {
  color: #3898ff
}

.sl-placeholder-success::-ms-input-placeholder {
  color: #0ea06f
}

.sl-placeholder-success::placeholder {
  color: #0ea06f
}

.sl-placeholder-warning::-ms-input-placeholder {
  color: #f3602b
}

.sl-placeholder-warning::placeholder {
  color: #f3602b
}

.sl-placeholder-danger::-ms-input-placeholder {
  color: #f05151
}

.sl-placeholder-danger::placeholder {
  color: #f05151
}

.sl-pointer-events-none {
  pointer-events: none
}

.sl-pointer-events-auto {
  pointer-events: auto
}

.sl-static {
  position: static
}

.sl-fixed {
  position: fixed
}

.sl-absolute {
  position: absolute
}

.sl-relative {
  position: relative
}

.sl-sticky {
  position: -webkit-sticky;
  position: sticky
}

.sl-resize-none {
  resize: none
}

.sl-resize-y {
  resize: vertical
}

.sl-resize-x {
  resize: horizontal
}

.sl-resize {
  resize: both
}

.sl-ring-primary {
  --tw-ring-color: hsla(var(--primary-h), 80%, 61%, var(--tw-ring-opacity))
}

.sl-ring-success {
  --tw-ring-color: hsla(var(--success-h), 84%, 34%, var(--tw-ring-opacity))
}

.sl-ring-warning {
  --tw-ring-color: hsla(var(--warning-h), 89%, 56%, var(--tw-ring-opacity))
}

.sl-ring-danger {
  --tw-ring-color: hsla(var(--danger-h), 84%, 63%, var(--tw-ring-opacity))
}

.focus\:sl-ring-primary:focus {
  --tw-ring-color: hsla(var(--primary-h), 80%, 61%, var(--tw-ring-opacity))
}

.focus\:sl-ring-success:focus {
  --tw-ring-color: hsla(var(--success-h), 84%, 34%, var(--tw-ring-opacity))
}

.focus\:sl-ring-warning:focus {
  --tw-ring-color: hsla(var(--warning-h), 89%, 56%, var(--tw-ring-opacity))
}

.focus\:sl-ring-danger:focus {
  --tw-ring-color: hsla(var(--danger-h), 84%, 63%, var(--tw-ring-opacity))
}

.sl-ring-opacity-0 {
  --tw-ring-opacity: 0
}

.sl-ring-opacity-5 {
  --tw-ring-opacity: 0.05
}

.sl-ring-opacity-10 {
  --tw-ring-opacity: 0.1
}

.sl-ring-opacity-20 {
  --tw-ring-opacity: 0.2
}

.sl-ring-opacity-30 {
  --tw-ring-opacity: 0.3
}

.sl-ring-opacity-40 {
  --tw-ring-opacity: 0.4
}

.sl-ring-opacity-50 {
  --tw-ring-opacity: 0.5
}

.sl-ring-opacity-60 {
  --tw-ring-opacity: 0.6
}

.sl-ring-opacity-70 {
  --tw-ring-opacity: 0.7
}

.sl-ring-opacity-90 {
  --tw-ring-opacity: 0.9
}

.sl-ring-opacity-100 {
  --tw-ring-opacity: 1
}

.focus\:sl-ring-opacity-0:focus {
  --tw-ring-opacity: 0
}

.focus\:sl-ring-opacity-5:focus {
  --tw-ring-opacity: 0.05
}

.focus\:sl-ring-opacity-10:focus {
  --tw-ring-opacity: 0.1
}

.focus\:sl-ring-opacity-20:focus {
  --tw-ring-opacity: 0.2
}

.focus\:sl-ring-opacity-30:focus {
  --tw-ring-opacity: 0.3
}

.focus\:sl-ring-opacity-40:focus {
  --tw-ring-opacity: 0.4
}

.focus\:sl-ring-opacity-50:focus {
  --tw-ring-opacity: 0.5
}

.focus\:sl-ring-opacity-60:focus {
  --tw-ring-opacity: 0.6
}

.focus\:sl-ring-opacity-70:focus {
  --tw-ring-opacity: 0.7
}

.focus\:sl-ring-opacity-90:focus {
  --tw-ring-opacity: 0.9
}

.focus\:sl-ring-opacity-100:focus {
  --tw-ring-opacity: 1
}

* {
  --tw-ring-inset: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(147, 197, 253, .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.sl-ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.sl-ring-inset {
  --tw-ring-inset: inset
}

.focus\:sl-ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:sl-ring-inset:focus {
  --tw-ring-inset: inset
}

.sl-stroke-transparent {
  stroke: transparent
}

.sl-stroke-current {
  stroke: currentColor
}

.sl-stroke-lighten-100 {
  stroke: var(--color-lighten-100)
}

.sl-stroke-darken-100 {
  stroke: var(--color-darken-100)
}

.sl-stroke-primary {
  stroke: var(--color-primary)
}

.sl-stroke-primary-tint {
  stroke: var(--color-primary-tint)
}

.sl-stroke-primary-light {
  stroke: var(--color-primary-light)
}

.sl-stroke-primary-dark {
  stroke: var(--color-primary-dark)
}

.sl-stroke-primary-darker {
  stroke: var(--color-primary-darker)
}

.sl-stroke-success {
  stroke: var(--color-success)
}

.sl-stroke-success-tint {
  stroke: var(--color-success-tint)
}

.sl-stroke-success-light {
  stroke: var(--color-success-light)
}

.sl-stroke-success-dark {
  stroke: var(--color-success-dark)
}

.sl-stroke-success-darker {
  stroke: var(--color-success-darker)
}

.sl-stroke-warning {
  stroke: var(--color-warning)
}

.sl-stroke-warning-tint {
  stroke: var(--color-warning-tint)
}

.sl-stroke-warning-light {
  stroke: var(--color-warning-light)
}

.sl-stroke-warning-dark {
  stroke: var(--color-warning-dark)
}

.sl-stroke-warning-darker {
  stroke: var(--color-warning-darker)
}

.sl-stroke-danger {
  stroke: var(--color-danger)
}

.sl-stroke-danger-tint {
  stroke: var(--color-danger-tint)
}

.sl-stroke-danger-light {
  stroke: var(--color-danger-light)
}

.sl-stroke-danger-dark {
  stroke: var(--color-danger-dark)
}

.sl-stroke-danger-darker {
  stroke: var(--color-danger-darker)
}

.sl-stroke-code {
  stroke: var(--color-code)
}

.sl-stroke-on-code {
  stroke: var(--color-on-code)
}

.sl-stroke-on-primary {
  stroke: var(--color-on-primary)
}

.sl-stroke-on-success {
  stroke: var(--color-on-success)
}

.sl-stroke-on-warning {
  stroke: var(--color-on-warning)
}

.sl-stroke-on-danger {
  stroke: var(--color-on-danger)
}

.sl-stroke-text {
  stroke: var(--color-text)
}

.sl-table-auto {
  table-layout: auto
}

.sl-table-fixed {
  table-layout: fixed
}

.sl-text-left {
  text-align: left
}

.sl-text-center {
  text-align: center
}

.sl-text-right {
  text-align: right
}

.sl-text-justify {
  text-align: justify
}

.sl-text-transparent {
  color: transparent
}

.sl-text-current {
  color: currentColor
}

.sl-text-lighten-100 {
  color: var(--color-lighten-100)
}

.sl-text-darken-100 {
  color: var(--color-darken-100)
}

.sl-text-primary {
  color: var(--color-primary)
}

.sl-text-primary-tint {
  color: var(--color-primary-tint)
}

.sl-text-primary-light {
  color: var(--color-primary-light)
}

.sl-text-primary-dark {
  color: var(--color-primary-dark)
}

.sl-text-primary-darker {
  color: var(--color-primary-darker)
}

.sl-text-success {
  color: var(--color-success)
}

.sl-text-success-tint {
  color: var(--color-success-tint)
}

.sl-text-success-light {
  color: var(--color-success-light)
}

.sl-text-success-dark {
  color: var(--color-success-dark)
}

.sl-text-success-darker {
  color: var(--color-success-darker)
}

.sl-text-warning {
  color: var(--color-warning)
}

.sl-text-warning-tint {
  color: var(--color-warning-tint)
}

.sl-text-warning-light {
  color: var(--color-warning-light)
}

.sl-text-warning-dark {
  color: var(--color-warning-dark)
}

.sl-text-warning-darker {
  color: var(--color-warning-darker)
}

.sl-text-danger {
  color: var(--color-danger)
}

.sl-text-danger-tint {
  color: var(--color-danger-tint)
}

.sl-text-danger-light {
  color: var(--color-danger-light)
}

.sl-text-danger-dark {
  color: var(--color-danger-dark)
}

.sl-text-danger-darker {
  color: var(--color-danger-darker)
}

.sl-text-code {
  color: var(--color-code)
}

.sl-text-on-code {
  color: var(--color-on-code)
}

.sl-text-on-primary {
  color: var(--color-on-primary)
}

.sl-text-on-success {
  color: var(--color-on-success)
}

.sl-text-on-warning {
  color: var(--color-on-warning)
}

.sl-text-on-danger {
  color: var(--color-on-danger)
}

.sl-text-body {
  color: var(--color-text)
}

.sl-text-muted {
  color: var(--color-text-muted)
}

.sl-text-light {
  color: var(--color-text-light)
}

.sl-text-heading {
  color: var(--color-text-heading)
}

.sl-text-paragraph {
  color: var(--color-text-paragraph)
}

.sl-text-canvas-50 {
  color: var(--color-canvas-50)
}

.sl-text-canvas-100 {
  color: var(--color-canvas-100)
}

.sl-text-canvas-200 {
  color: var(--color-canvas-200)
}

.sl-text-canvas-300 {
  color: var(--color-canvas-300)
}

.sl-text-canvas-pure {
  color: var(--color-canvas-pure)
}

.sl-text-canvas {
  color: var(--color-canvas)
}

.sl-text-canvas-dialog {
  color: var(--color-canvas-dialog)
}

.sl-text-link {
  color: var(--color-link)
}

.sl-text-link-dark {
  color: var(--color-link-dark)
}

.hover\:sl-text-transparent:hover {
  color: transparent
}

.hover\:sl-text-current:hover {
  color: currentColor
}

.hover\:sl-text-lighten-100:hover {
  color: var(--color-lighten-100)
}

.hover\:sl-text-darken-100:hover {
  color: var(--color-darken-100)
}

.hover\:sl-text-primary:hover {
  color: var(--color-primary)
}

.hover\:sl-text-primary-tint:hover {
  color: var(--color-primary-tint)
}

.hover\:sl-text-primary-light:hover {
  color: var(--color-primary-light)
}

.hover\:sl-text-primary-dark:hover {
  color: var(--color-primary-dark)
}

.hover\:sl-text-primary-darker:hover {
  color: var(--color-primary-darker)
}

.hover\:sl-text-success:hover {
  color: var(--color-success)
}

.hover\:sl-text-success-tint:hover {
  color: var(--color-success-tint)
}

.hover\:sl-text-success-light:hover {
  color: var(--color-success-light)
}

.hover\:sl-text-success-dark:hover {
  color: var(--color-success-dark)
}

.hover\:sl-text-success-darker:hover {
  color: var(--color-success-darker)
}

.hover\:sl-text-warning:hover {
  color: var(--color-warning)
}

.hover\:sl-text-warning-tint:hover {
  color: var(--color-warning-tint)
}

.hover\:sl-text-warning-light:hover {
  color: var(--color-warning-light)
}

.hover\:sl-text-warning-dark:hover {
  color: var(--color-warning-dark)
}

.hover\:sl-text-warning-darker:hover {
  color: var(--color-warning-darker)
}

.hover\:sl-text-danger:hover {
  color: var(--color-danger)
}

.hover\:sl-text-danger-tint:hover {
  color: var(--color-danger-tint)
}

.hover\:sl-text-danger-light:hover {
  color: var(--color-danger-light)
}

.hover\:sl-text-danger-dark:hover {
  color: var(--color-danger-dark)
}

.hover\:sl-text-danger-darker:hover {
  color: var(--color-danger-darker)
}

.hover\:sl-text-code:hover {
  color: var(--color-code)
}

.hover\:sl-text-on-code:hover {
  color: var(--color-on-code)
}

.hover\:sl-text-on-primary:hover {
  color: var(--color-on-primary)
}

.hover\:sl-text-on-success:hover {
  color: var(--color-on-success)
}

.hover\:sl-text-on-warning:hover {
  color: var(--color-on-warning)
}

.hover\:sl-text-on-danger:hover {
  color: var(--color-on-danger)
}

.hover\:sl-text-body:hover {
  color: var(--color-text)
}

.hover\:sl-text-muted:hover {
  color: var(--color-text-muted)
}

.hover\:sl-text-light:hover {
  color: var(--color-text-light)
}

.hover\:sl-text-heading:hover {
  color: var(--color-text-heading)
}

.hover\:sl-text-paragraph:hover {
  color: var(--color-text-paragraph)
}

.hover\:sl-text-canvas-50:hover {
  color: var(--color-canvas-50)
}

.hover\:sl-text-canvas-100:hover {
  color: var(--color-canvas-100)
}

.hover\:sl-text-canvas-200:hover {
  color: var(--color-canvas-200)
}

.hover\:sl-text-canvas-300:hover {
  color: var(--color-canvas-300)
}

.hover\:sl-text-canvas-pure:hover {
  color: var(--color-canvas-pure)
}

.hover\:sl-text-canvas:hover {
  color: var(--color-canvas)
}

.hover\:sl-text-canvas-dialog:hover {
  color: var(--color-canvas-dialog)
}

.hover\:sl-text-link:hover {
  color: var(--color-link)
}

.hover\:sl-text-link-dark:hover {
  color: var(--color-link-dark)
}

.focus\:sl-text-transparent:focus {
  color: transparent
}

.focus\:sl-text-current:focus {
  color: currentColor
}

.focus\:sl-text-lighten-100:focus {
  color: var(--color-lighten-100)
}

.focus\:sl-text-darken-100:focus {
  color: var(--color-darken-100)
}

.focus\:sl-text-primary:focus {
  color: var(--color-primary)
}

.focus\:sl-text-primary-tint:focus {
  color: var(--color-primary-tint)
}

.focus\:sl-text-primary-light:focus {
  color: var(--color-primary-light)
}

.focus\:sl-text-primary-dark:focus {
  color: var(--color-primary-dark)
}

.focus\:sl-text-primary-darker:focus {
  color: var(--color-primary-darker)
}

.focus\:sl-text-success:focus {
  color: var(--color-success)
}

.focus\:sl-text-success-tint:focus {
  color: var(--color-success-tint)
}

.focus\:sl-text-success-light:focus {
  color: var(--color-success-light)
}

.focus\:sl-text-success-dark:focus {
  color: var(--color-success-dark)
}

.focus\:sl-text-success-darker:focus {
  color: var(--color-success-darker)
}

.focus\:sl-text-warning:focus {
  color: var(--color-warning)
}

.focus\:sl-text-warning-tint:focus {
  color: var(--color-warning-tint)
}

.focus\:sl-text-warning-light:focus {
  color: var(--color-warning-light)
}

.focus\:sl-text-warning-dark:focus {
  color: var(--color-warning-dark)
}

.focus\:sl-text-warning-darker:focus {
  color: var(--color-warning-darker)
}

.focus\:sl-text-danger:focus {
  color: var(--color-danger)
}

.focus\:sl-text-danger-tint:focus {
  color: var(--color-danger-tint)
}

.focus\:sl-text-danger-light:focus {
  color: var(--color-danger-light)
}

.focus\:sl-text-danger-dark:focus {
  color: var(--color-danger-dark)
}

.focus\:sl-text-danger-darker:focus {
  color: var(--color-danger-darker)
}

.focus\:sl-text-code:focus {
  color: var(--color-code)
}

.focus\:sl-text-on-code:focus {
  color: var(--color-on-code)
}

.focus\:sl-text-on-primary:focus {
  color: var(--color-on-primary)
}

.focus\:sl-text-on-success:focus {
  color: var(--color-on-success)
}

.focus\:sl-text-on-warning:focus {
  color: var(--color-on-warning)
}

.focus\:sl-text-on-danger:focus {
  color: var(--color-on-danger)
}

.focus\:sl-text-body:focus {
  color: var(--color-text)
}

.focus\:sl-text-muted:focus {
  color: var(--color-text-muted)
}

.focus\:sl-text-light:focus {
  color: var(--color-text-light)
}

.focus\:sl-text-heading:focus {
  color: var(--color-text-heading)
}

.focus\:sl-text-paragraph:focus {
  color: var(--color-text-paragraph)
}

.focus\:sl-text-canvas-50:focus {
  color: var(--color-canvas-50)
}

.focus\:sl-text-canvas-100:focus {
  color: var(--color-canvas-100)
}

.focus\:sl-text-canvas-200:focus {
  color: var(--color-canvas-200)
}

.focus\:sl-text-canvas-300:focus {
  color: var(--color-canvas-300)
}

.focus\:sl-text-canvas-pure:focus {
  color: var(--color-canvas-pure)
}

.focus\:sl-text-canvas:focus {
  color: var(--color-canvas)
}

.focus\:sl-text-canvas-dialog:focus {
  color: var(--color-canvas-dialog)
}

.focus\:sl-text-link:focus {
  color: var(--color-link)
}

.focus\:sl-text-link-dark:focus {
  color: var(--color-link-dark)
}

.disabled\:sl-text-transparent:disabled {
  color: transparent
}

.disabled\:sl-text-current:disabled {
  color: currentColor
}

.disabled\:sl-text-lighten-100:disabled {
  color: var(--color-lighten-100)
}

.disabled\:sl-text-darken-100:disabled {
  color: var(--color-darken-100)
}

.disabled\:sl-text-primary:disabled {
  color: var(--color-primary)
}

.disabled\:sl-text-primary-tint:disabled {
  color: var(--color-primary-tint)
}

.disabled\:sl-text-primary-light:disabled {
  color: var(--color-primary-light)
}

.disabled\:sl-text-primary-dark:disabled {
  color: var(--color-primary-dark)
}

.disabled\:sl-text-primary-darker:disabled {
  color: var(--color-primary-darker)
}

.disabled\:sl-text-success:disabled {
  color: var(--color-success)
}

.disabled\:sl-text-success-tint:disabled {
  color: var(--color-success-tint)
}

.disabled\:sl-text-success-light:disabled {
  color: var(--color-success-light)
}

.disabled\:sl-text-success-dark:disabled {
  color: var(--color-success-dark)
}

.disabled\:sl-text-success-darker:disabled {
  color: var(--color-success-darker)
}

.disabled\:sl-text-warning:disabled {
  color: var(--color-warning)
}

.disabled\:sl-text-warning-tint:disabled {
  color: var(--color-warning-tint)
}

.disabled\:sl-text-warning-light:disabled {
  color: var(--color-warning-light)
}

.disabled\:sl-text-warning-dark:disabled {
  color: var(--color-warning-dark)
}

.disabled\:sl-text-warning-darker:disabled {
  color: var(--color-warning-darker)
}

.disabled\:sl-text-danger:disabled {
  color: var(--color-danger)
}

.disabled\:sl-text-danger-tint:disabled {
  color: var(--color-danger-tint)
}

.disabled\:sl-text-danger-light:disabled {
  color: var(--color-danger-light)
}

.disabled\:sl-text-danger-dark:disabled {
  color: var(--color-danger-dark)
}

.disabled\:sl-text-danger-darker:disabled {
  color: var(--color-danger-darker)
}

.disabled\:sl-text-code:disabled {
  color: var(--color-code)
}

.disabled\:sl-text-on-code:disabled {
  color: var(--color-on-code)
}

.disabled\:sl-text-on-primary:disabled {
  color: var(--color-on-primary)
}

.disabled\:sl-text-on-success:disabled {
  color: var(--color-on-success)
}

.disabled\:sl-text-on-warning:disabled {
  color: var(--color-on-warning)
}

.disabled\:sl-text-on-danger:disabled {
  color: var(--color-on-danger)
}

.disabled\:sl-text-body:disabled {
  color: var(--color-text)
}

.disabled\:sl-text-muted:disabled {
  color: var(--color-text-muted)
}

.disabled\:sl-text-light:disabled {
  color: var(--color-text-light)
}

.disabled\:sl-text-heading:disabled {
  color: var(--color-text-heading)
}

.disabled\:sl-text-paragraph:disabled {
  color: var(--color-text-paragraph)
}

.disabled\:sl-text-canvas-50:disabled {
  color: var(--color-canvas-50)
}

.disabled\:sl-text-canvas-100:disabled {
  color: var(--color-canvas-100)
}

.disabled\:sl-text-canvas-200:disabled {
  color: var(--color-canvas-200)
}

.disabled\:sl-text-canvas-300:disabled {
  color: var(--color-canvas-300)
}

.disabled\:sl-text-canvas-pure:disabled {
  color: var(--color-canvas-pure)
}

.disabled\:sl-text-canvas:disabled {
  color: var(--color-canvas)
}

.disabled\:sl-text-canvas-dialog:disabled {
  color: var(--color-canvas-dialog)
}

.disabled\:sl-text-link:disabled {
  color: var(--color-link)
}

.disabled\:sl-text-link-dark:disabled {
  color: var(--color-link-dark)
}

.sl-underline {
  text-decoration: underline
}

.sl-line-through {
  text-decoration: line-through
}

.sl-no-underline {
  text-decoration: none
}

.sl-truncate {
  overflow: hidden;
  white-space: nowrap
}

.sl-overflow-ellipsis, .sl-truncate {
  text-overflow: ellipsis
}

.sl-overflow-clip {
  text-overflow: clip
}

.sl-uppercase {
  text-transform: uppercase
}

.sl-lowercase {
  text-transform: lowercase
}

.sl-capitalize {
  text-transform: capitalize
}

.sl-normal-case {
  text-transform: none
}

.sl-transform {
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.sl-transform, .sl-transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1
}

.sl-transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.sl-transform-none {
  transform: none
}

.sl-delay-75 {
  transition-delay: 75ms
}

.sl-delay-150 {
  transition-delay: .15s
}

.sl-delay-300 {
  transition-delay: .3s
}

.sl-delay-500 {
  transition-delay: .5s
}

.sl-delay-1000 {
  transition-delay: 1s
}

.sl-duration-75 {
  transition-duration: 75ms
}

.sl-duration-150 {
  transition-duration: .15s
}

.sl-duration-300 {
  transition-duration: .3s
}

.sl-duration-500 {
  transition-duration: .5s
}

.sl-duration-1000 {
  transition-duration: 1s
}

.sl-transition {
  transition-duration: .15s;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.sl-translate-x-0 {
  --tw-translate-x: 0px
}

.sl-translate-x-1 {
  --tw-translate-x: 4px
}

.sl-translate-x-2 {
  --tw-translate-x: 8px
}

.sl-translate-x-3 {
  --tw-translate-x: 12px
}

.sl-translate-x-4 {
  --tw-translate-x: 16px
}

.sl-translate-x-5 {
  --tw-translate-x: 20px
}

.sl-translate-x-6 {
  --tw-translate-x: 24px
}

.sl-translate-x-7 {
  --tw-translate-x: 28px
}

.sl-translate-x-8 {
  --tw-translate-x: 32px
}

.sl-translate-x-9 {
  --tw-translate-x: 36px
}

.sl-translate-x-10 {
  --tw-translate-x: 40px
}

.sl-translate-x-11 {
  --tw-translate-x: 44px
}

.sl-translate-x-12 {
  --tw-translate-x: 48px
}

.sl-translate-x-14 {
  --tw-translate-x: 56px
}

.sl-translate-x-16 {
  --tw-translate-x: 64px
}

.sl-translate-x-20 {
  --tw-translate-x: 80px
}

.sl-translate-x-24 {
  --tw-translate-x: 96px
}

.sl-translate-x-32 {
  --tw-translate-x: 128px
}

.sl-translate-x-40 {
  --tw-translate-x: 160px
}

.sl-translate-x-60 {
  --tw-translate-x: 240px
}

.sl-translate-x-80 {
  --tw-translate-x: 320px
}

.sl-translate-x-px {
  --tw-translate-x: 1px
}

.sl-translate-x-0\.5 {
  --tw-translate-x: 2px
}

.sl-translate-x-1\.5 {
  --tw-translate-x: 6px
}

.sl-translate-x-2\.5 {
  --tw-translate-x: 10px
}

.sl-translate-x-3\.5 {
  --tw-translate-x: 14px
}

.sl--translate-x-0 {
  --tw-translate-x: 0px
}

.sl--translate-x-1 {
  --tw-translate-x: -4px
}

.sl--translate-x-2 {
  --tw-translate-x: -8px
}

.sl--translate-x-3 {
  --tw-translate-x: -12px
}

.sl--translate-x-4 {
  --tw-translate-x: -16px
}

.sl--translate-x-5 {
  --tw-translate-x: -20px
}

.sl--translate-x-6 {
  --tw-translate-x: -24px
}

.sl--translate-x-7 {
  --tw-translate-x: -28px
}

.sl--translate-x-8 {
  --tw-translate-x: -32px
}

.sl--translate-x-9 {
  --tw-translate-x: -36px
}

.sl--translate-x-10 {
  --tw-translate-x: -40px
}

.sl--translate-x-11 {
  --tw-translate-x: -44px
}

.sl--translate-x-12 {
  --tw-translate-x: -48px
}

.sl--translate-x-14 {
  --tw-translate-x: -56px
}

.sl--translate-x-16 {
  --tw-translate-x: -64px
}

.sl--translate-x-20 {
  --tw-translate-x: -80px
}

.sl--translate-x-24 {
  --tw-translate-x: -96px
}

.sl--translate-x-32 {
  --tw-translate-x: -128px
}

.sl--translate-x-40 {
  --tw-translate-x: -160px
}

.sl--translate-x-60 {
  --tw-translate-x: -240px
}

.sl--translate-x-80 {
  --tw-translate-x: -320px
}

.sl--translate-x-px {
  --tw-translate-x: -1px
}

.sl--translate-x-0\.5 {
  --tw-translate-x: -2px
}

.sl--translate-x-1\.5 {
  --tw-translate-x: -6px
}

.sl--translate-x-2\.5 {
  --tw-translate-x: -10px
}

.sl--translate-x-3\.5 {
  --tw-translate-x: -14px
}

.sl-translate-y-0 {
  --tw-translate-y: 0px
}

.sl-translate-y-1 {
  --tw-translate-y: 4px
}

.sl-translate-y-2 {
  --tw-translate-y: 8px
}

.sl-translate-y-3 {
  --tw-translate-y: 12px
}

.sl-translate-y-4 {
  --tw-translate-y: 16px
}

.sl-translate-y-5 {
  --tw-translate-y: 20px
}

.sl-translate-y-6 {
  --tw-translate-y: 24px
}

.sl-translate-y-7 {
  --tw-translate-y: 28px
}

.sl-translate-y-8 {
  --tw-translate-y: 32px
}

.sl-translate-y-9 {
  --tw-translate-y: 36px
}

.sl-translate-y-10 {
  --tw-translate-y: 40px
}

.sl-translate-y-11 {
  --tw-translate-y: 44px
}

.sl-translate-y-12 {
  --tw-translate-y: 48px
}

.sl-translate-y-14 {
  --tw-translate-y: 56px
}

.sl-translate-y-16 {
  --tw-translate-y: 64px
}

.sl-translate-y-20 {
  --tw-translate-y: 80px
}

.sl-translate-y-24 {
  --tw-translate-y: 96px
}

.sl-translate-y-32 {
  --tw-translate-y: 128px
}

.sl-translate-y-40 {
  --tw-translate-y: 160px
}

.sl-translate-y-60 {
  --tw-translate-y: 240px
}

.sl-translate-y-80 {
  --tw-translate-y: 320px
}

.sl-translate-y-px {
  --tw-translate-y: 1px
}

.sl-translate-y-0\.5 {
  --tw-translate-y: 2px
}

.sl-translate-y-1\.5 {
  --tw-translate-y: 6px
}

.sl-translate-y-2\.5 {
  --tw-translate-y: 10px
}

.sl-translate-y-3\.5 {
  --tw-translate-y: 14px
}

.sl--translate-y-0 {
  --tw-translate-y: 0px
}

.sl--translate-y-1 {
  --tw-translate-y: -4px
}

.sl--translate-y-2 {
  --tw-translate-y: -8px
}

.sl--translate-y-3 {
  --tw-translate-y: -12px
}

.sl--translate-y-4 {
  --tw-translate-y: -16px
}

.sl--translate-y-5 {
  --tw-translate-y: -20px
}

.sl--translate-y-6 {
  --tw-translate-y: -24px
}

.sl--translate-y-7 {
  --tw-translate-y: -28px
}

.sl--translate-y-8 {
  --tw-translate-y: -32px
}

.sl--translate-y-9 {
  --tw-translate-y: -36px
}

.sl--translate-y-10 {
  --tw-translate-y: -40px
}

.sl--translate-y-11 {
  --tw-translate-y: -44px
}

.sl--translate-y-12 {
  --tw-translate-y: -48px
}

.sl--translate-y-14 {
  --tw-translate-y: -56px
}

.sl--translate-y-16 {
  --tw-translate-y: -64px
}

.sl--translate-y-20 {
  --tw-translate-y: -80px
}

.sl--translate-y-24 {
  --tw-translate-y: -96px
}

.sl--translate-y-32 {
  --tw-translate-y: -128px
}

.sl--translate-y-40 {
  --tw-translate-y: -160px
}

.sl--translate-y-60 {
  --tw-translate-y: -240px
}

.sl--translate-y-80 {
  --tw-translate-y: -320px
}

.sl--translate-y-px {
  --tw-translate-y: -1px
}

.sl--translate-y-0\.5 {
  --tw-translate-y: -2px
}

.sl--translate-y-1\.5 {
  --tw-translate-y: -6px
}

.sl--translate-y-2\.5 {
  --tw-translate-y: -10px
}

.sl--translate-y-3\.5 {
  --tw-translate-y: -14px
}

.hover\:sl-translate-x-0:hover {
  --tw-translate-x: 0px
}

.hover\:sl-translate-x-1:hover {
  --tw-translate-x: 4px
}

.hover\:sl-translate-x-2:hover {
  --tw-translate-x: 8px
}

.hover\:sl-translate-x-3:hover {
  --tw-translate-x: 12px
}

.hover\:sl-translate-x-4:hover {
  --tw-translate-x: 16px
}

.hover\:sl-translate-x-5:hover {
  --tw-translate-x: 20px
}

.hover\:sl-translate-x-6:hover {
  --tw-translate-x: 24px
}

.hover\:sl-translate-x-7:hover {
  --tw-translate-x: 28px
}

.hover\:sl-translate-x-8:hover {
  --tw-translate-x: 32px
}

.hover\:sl-translate-x-9:hover {
  --tw-translate-x: 36px
}

.hover\:sl-translate-x-10:hover {
  --tw-translate-x: 40px
}

.hover\:sl-translate-x-11:hover {
  --tw-translate-x: 44px
}

.hover\:sl-translate-x-12:hover {
  --tw-translate-x: 48px
}

.hover\:sl-translate-x-14:hover {
  --tw-translate-x: 56px
}

.hover\:sl-translate-x-16:hover {
  --tw-translate-x: 64px
}

.hover\:sl-translate-x-20:hover {
  --tw-translate-x: 80px
}

.hover\:sl-translate-x-24:hover {
  --tw-translate-x: 96px
}

.hover\:sl-translate-x-32:hover {
  --tw-translate-x: 128px
}

.hover\:sl-translate-x-40:hover {
  --tw-translate-x: 160px
}

.hover\:sl-translate-x-60:hover {
  --tw-translate-x: 240px
}

.hover\:sl-translate-x-80:hover {
  --tw-translate-x: 320px
}

.hover\:sl-translate-x-px:hover {
  --tw-translate-x: 1px
}

.hover\:sl-translate-x-0\.5:hover {
  --tw-translate-x: 2px
}

.hover\:sl-translate-x-1\.5:hover {
  --tw-translate-x: 6px
}

.hover\:sl-translate-x-2\.5:hover {
  --tw-translate-x: 10px
}

.hover\:sl-translate-x-3\.5:hover {
  --tw-translate-x: 14px
}

.hover\:sl--translate-x-0:hover {
  --tw-translate-x: 0px
}

.hover\:sl--translate-x-1:hover {
  --tw-translate-x: -4px
}

.hover\:sl--translate-x-2:hover {
  --tw-translate-x: -8px
}

.hover\:sl--translate-x-3:hover {
  --tw-translate-x: -12px
}

.hover\:sl--translate-x-4:hover {
  --tw-translate-x: -16px
}

.hover\:sl--translate-x-5:hover {
  --tw-translate-x: -20px
}

.hover\:sl--translate-x-6:hover {
  --tw-translate-x: -24px
}

.hover\:sl--translate-x-7:hover {
  --tw-translate-x: -28px
}

.hover\:sl--translate-x-8:hover {
  --tw-translate-x: -32px
}

.hover\:sl--translate-x-9:hover {
  --tw-translate-x: -36px
}

.hover\:sl--translate-x-10:hover {
  --tw-translate-x: -40px
}

.hover\:sl--translate-x-11:hover {
  --tw-translate-x: -44px
}

.hover\:sl--translate-x-12:hover {
  --tw-translate-x: -48px
}

.hover\:sl--translate-x-14:hover {
  --tw-translate-x: -56px
}

.hover\:sl--translate-x-16:hover {
  --tw-translate-x: -64px
}

.hover\:sl--translate-x-20:hover {
  --tw-translate-x: -80px
}

.hover\:sl--translate-x-24:hover {
  --tw-translate-x: -96px
}

.hover\:sl--translate-x-32:hover {
  --tw-translate-x: -128px
}

.hover\:sl--translate-x-40:hover {
  --tw-translate-x: -160px
}

.hover\:sl--translate-x-60:hover {
  --tw-translate-x: -240px
}

.hover\:sl--translate-x-80:hover {
  --tw-translate-x: -320px
}

.hover\:sl--translate-x-px:hover {
  --tw-translate-x: -1px
}

.hover\:sl--translate-x-0\.5:hover {
  --tw-translate-x: -2px
}

.hover\:sl--translate-x-1\.5:hover {
  --tw-translate-x: -6px
}

.hover\:sl--translate-x-2\.5:hover {
  --tw-translate-x: -10px
}

.hover\:sl--translate-x-3\.5:hover {
  --tw-translate-x: -14px
}

.hover\:sl-translate-y-0:hover {
  --tw-translate-y: 0px
}

.hover\:sl-translate-y-1:hover {
  --tw-translate-y: 4px
}

.hover\:sl-translate-y-2:hover {
  --tw-translate-y: 8px
}

.hover\:sl-translate-y-3:hover {
  --tw-translate-y: 12px
}

.hover\:sl-translate-y-4:hover {
  --tw-translate-y: 16px
}

.hover\:sl-translate-y-5:hover {
  --tw-translate-y: 20px
}

.hover\:sl-translate-y-6:hover {
  --tw-translate-y: 24px
}

.hover\:sl-translate-y-7:hover {
  --tw-translate-y: 28px
}

.hover\:sl-translate-y-8:hover {
  --tw-translate-y: 32px
}

.hover\:sl-translate-y-9:hover {
  --tw-translate-y: 36px
}

.hover\:sl-translate-y-10:hover {
  --tw-translate-y: 40px
}

.hover\:sl-translate-y-11:hover {
  --tw-translate-y: 44px
}

.hover\:sl-translate-y-12:hover {
  --tw-translate-y: 48px
}

.hover\:sl-translate-y-14:hover {
  --tw-translate-y: 56px
}

.hover\:sl-translate-y-16:hover {
  --tw-translate-y: 64px
}

.hover\:sl-translate-y-20:hover {
  --tw-translate-y: 80px
}

.hover\:sl-translate-y-24:hover {
  --tw-translate-y: 96px
}

.hover\:sl-translate-y-32:hover {
  --tw-translate-y: 128px
}

.hover\:sl-translate-y-40:hover {
  --tw-translate-y: 160px
}

.hover\:sl-translate-y-60:hover {
  --tw-translate-y: 240px
}

.hover\:sl-translate-y-80:hover {
  --tw-translate-y: 320px
}

.hover\:sl-translate-y-px:hover {
  --tw-translate-y: 1px
}

.hover\:sl-translate-y-0\.5:hover {
  --tw-translate-y: 2px
}

.hover\:sl-translate-y-1\.5:hover {
  --tw-translate-y: 6px
}

.hover\:sl-translate-y-2\.5:hover {
  --tw-translate-y: 10px
}

.hover\:sl-translate-y-3\.5:hover {
  --tw-translate-y: 14px
}

.hover\:sl--translate-y-0:hover {
  --tw-translate-y: 0px
}

.hover\:sl--translate-y-1:hover {
  --tw-translate-y: -4px
}

.hover\:sl--translate-y-2:hover {
  --tw-translate-y: -8px
}

.hover\:sl--translate-y-3:hover {
  --tw-translate-y: -12px
}

.hover\:sl--translate-y-4:hover {
  --tw-translate-y: -16px
}

.hover\:sl--translate-y-5:hover {
  --tw-translate-y: -20px
}

.hover\:sl--translate-y-6:hover {
  --tw-translate-y: -24px
}

.hover\:sl--translate-y-7:hover {
  --tw-translate-y: -28px
}

.hover\:sl--translate-y-8:hover {
  --tw-translate-y: -32px
}

.hover\:sl--translate-y-9:hover {
  --tw-translate-y: -36px
}

.hover\:sl--translate-y-10:hover {
  --tw-translate-y: -40px
}

.hover\:sl--translate-y-11:hover {
  --tw-translate-y: -44px
}

.hover\:sl--translate-y-12:hover {
  --tw-translate-y: -48px
}

.hover\:sl--translate-y-14:hover {
  --tw-translate-y: -56px
}

.hover\:sl--translate-y-16:hover {
  --tw-translate-y: -64px
}

.hover\:sl--translate-y-20:hover {
  --tw-translate-y: -80px
}

.hover\:sl--translate-y-24:hover {
  --tw-translate-y: -96px
}

.hover\:sl--translate-y-32:hover {
  --tw-translate-y: -128px
}

.hover\:sl--translate-y-40:hover {
  --tw-translate-y: -160px
}

.hover\:sl--translate-y-60:hover {
  --tw-translate-y: -240px
}

.hover\:sl--translate-y-80:hover {
  --tw-translate-y: -320px
}

.hover\:sl--translate-y-px:hover {
  --tw-translate-y: -1px
}

.hover\:sl--translate-y-0\.5:hover {
  --tw-translate-y: -2px
}

.hover\:sl--translate-y-1\.5:hover {
  --tw-translate-y: -6px
}

.hover\:sl--translate-y-2\.5:hover {
  --tw-translate-y: -10px
}

.hover\:sl--translate-y-3\.5:hover {
  --tw-translate-y: -14px
}

.sl-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.sl-select-text {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text
}

.sl-select-all {
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all
}

.sl-select-auto {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto
}

.sl-align-baseline {
  vertical-align: initial
}

.sl-align-top {
  vertical-align: top
}

.sl-align-middle {
  vertical-align: middle
}

.sl-align-bottom {
  vertical-align: bottom
}

.sl-align-text-top {
  vertical-align: text-top
}

.sl-align-text-bottom {
  vertical-align: text-bottom
}

.sl-visible {
  visibility: visible
}

.sl-invisible {
  visibility: hidden
}

.sl-group:hover .group-hover\:sl-visible {
  visibility: visible
}

.sl-group:hover .group-hover\:sl-invisible {
  visibility: hidden
}

.sl-group:focus .group-focus\:sl-visible {
  visibility: visible
}

.sl-group:focus .group-focus\:sl-invisible {
  visibility: hidden
}

.sl-whitespace-normal {
  white-space: normal
}

.sl-whitespace-nowrap {
  white-space: nowrap
}

.sl-whitespace-pre {
  white-space: pre
}

.sl-whitespace-pre-line {
  white-space: pre-line
}

.sl-whitespace-pre-wrap {
  white-space: pre-wrap
}

.sl-w-0 {
  width: 0
}

.sl-w-1 {
  width: 4px
}

.sl-w-2 {
  width: 8px
}

.sl-w-3 {
  width: 12px
}

.sl-w-4 {
  width: 16px
}

.sl-w-5 {
  width: 20px
}

.sl-w-6 {
  width: 24px
}

.sl-w-7 {
  width: 28px
}

.sl-w-8 {
  width: 32px
}

.sl-w-9 {
  width: 36px
}

.sl-w-10 {
  width: 40px
}

.sl-w-11 {
  width: 44px
}

.sl-w-12 {
  width: 48px
}

.sl-w-14 {
  width: 56px
}

.sl-w-16 {
  width: 64px
}

.sl-w-20 {
  width: 80px
}

.sl-w-24 {
  width: 96px
}

.sl-w-32 {
  width: 128px
}

.sl-w-40 {
  width: 160px
}

.sl-w-60 {
  width: 240px
}

.sl-w-80 {
  width: 320px
}

.sl-w-auto {
  width: auto
}

.sl-w-px {
  width: 1px
}

.sl-w-0\.5 {
  width: 2px
}

.sl-w-1\.5 {
  width: 6px
}

.sl-w-2\.5 {
  width: 10px
}

.sl-w-3\.5 {
  width: 14px
}

.sl-w-xs {
  width: 20px
}

.sl-w-sm {
  width: 24px
}

.sl-w-md {
  width: 32px
}

.sl-w-lg {
  width: 36px
}

.sl-w-xl {
  width: 44px
}

.sl-w-2xl {
  width: 52px
}

.sl-w-3xl {
  width: 60px
}

.sl-w-1\/2 {
  width: 50%
}

.sl-w-1\/3 {
  width: 33.333333%
}

.sl-w-2\/3 {
  width: 66.666667%
}

.sl-w-1\/4 {
  width: 25%
}

.sl-w-2\/4 {
  width: 50%
}

.sl-w-3\/4 {
  width: 75%
}

.sl-w-1\/5 {
  width: 20%
}

.sl-w-2\/5 {
  width: 40%
}

.sl-w-3\/5 {
  width: 60%
}

.sl-w-4\/5 {
  width: 80%
}

.sl-w-1\/6 {
  width: 16.666667%
}

.sl-w-2\/6 {
  width: 33.333333%
}

.sl-w-3\/6 {
  width: 50%
}

.sl-w-4\/6 {
  width: 66.666667%
}

.sl-w-5\/6 {
  width: 83.333333%
}

.sl-w-full {
  width: 100%
}

.sl-w-screen {
  width: 100vw
}

.sl-w-min {
  width: -moz-min-content;
  width: min-content
}

.sl-w-max {
  width: -moz-max-content;
  width: max-content
}

.sl-break-normal {
  overflow-wrap: normal;
  word-break: normal
}

.sl-break-words {
  overflow-wrap: break-word
}

.sl-break-all {
  word-break: break-all
}

.sl-z-0 {
  z-index: 0
}

.sl-z-10 {
  z-index: 10
}

.sl-z-20 {
  z-index: 20
}

.sl-z-30 {
  z-index: 30
}

.sl-z-40 {
  z-index: 40
}

.sl-z-50 {
  z-index: 50
}

.sl-z-auto {
  z-index: auto
}

.focus\:sl-z-0:focus {
  z-index: 0
}

.focus\:sl-z-10:focus {
  z-index: 10
}

.focus\:sl-z-20:focus {
  z-index: 20
}

.focus\:sl-z-30:focus {
  z-index: 30
}

.focus\:sl-z-40:focus {
  z-index: 40
}

.focus\:sl-z-50:focus {
  z-index: 50
}

.focus\:sl-z-auto:focus {
  z-index: auto
}

:root {
  --font-prose: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-ui: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-mono: "SF Mono", ui-monospace, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-code: var(--font-mono);
  --fs-paragraph-leading: 22px;
  --fs-paragraph: 16px;
  --fs-code: 14px;
  --fs-paragraph-small: 14px;
  --fs-paragraph-tiny: 12px;
  --lh-paragraph-leading: 1.875;
  --lh-paragraph: 1.625;
  --lh-code: 1.5;
  --lh-paragraph-small: 1.625;
  --lh-paragraph-tiny: 1.625;
  --color-code: var(--color-canvas-tint);
  --color-on-code: var(--color-text-heading)
}

.sl-aspect-ratio:before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: calc(1 / var(--ratio) * 100%)
}

.sl-aspect-ratio > :not(style) {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.sl-aspect-ratio > img, .sl-aspect-ratio > video {
  object-fit: cover
}

.sl-badge {
  align-items: center;
  border-width: 1px;
  display: inline-flex;
  outline: 2px solid transparent;
  outline-offset: 2px
}

.sl-badge a {
  color: var(--color-text-muted)
}

.sl-badge a:hover {
  color: var(--color-text);
  cursor: pointer
}

.sl-button {
  align-items: center;
  display: inline-flex;
  line-height: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.sl-button-group > .sl-button:not(:first-child):not(:last-child) {
  border-radius: 0;
  border-right: 0
}

.sl-button-group > .sl-button:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-right: 0;
  border-top-right-radius: 0
}

.sl-button-group > .sl-button:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.sl-image--inverted {
  filter: invert(1) hue-rotate(180deg);
  mix-blend-mode: screen
}

.Link, .Link > code {
  color: var(--color-link)
}

.Link:hover, .Link:hover > code {
  color: var(--color-link-dark)
}

.sl-link-heading:hover .sl-link-heading__icon {
  opacity: 1
}

.sl-link-heading__icon {
  opacity: 0
}

.sl-menu {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.sl-menu--pointer-interactions .sl-menu-item:not(.sl-menu-item--disabled):hover {
  background-color: var(--color-primary);
  color: var(--color-on-primary)
}

.sl-menu--pointer-interactions .sl-menu-item:not(.sl-menu-item--disabled):hover .sl-menu-item__description {
  color: var(--color-on-primary)
}

.sl-menu--pointer-interactions .sl-menu-item:not(.sl-menu-item--disabled):hover .sl-menu-item__icon {
  color: var(--color-on-primary) !important
}

.sl-menu-item__link-icon, .sl-menu-item__meta {
  opacity: .6
}

.sl-menu-item--focused {
  background-color: var(--color-primary);
  color: var(--color-on-primary)
}

.sl-menu-item--focused .sl-menu-item__link-icon, .sl-menu-item--focused .sl-menu-item__meta {
  opacity: 1
}

.sl-menu-item--focused .sl-menu-item__description {
  color: var(--color-on-primary)
}

.sl-menu-item--focused .sl-menu-item__icon {
  color: var(--color-on-primary) !important
}

.sl-menu-item--submenu-active {
  background-color: var(--color-primary-tint)
}

.sl-menu-item__title-wrapper {
  max-width: 250px
}

.sl-menu-item__description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.sl-popover {
  --tw-blur: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-brightness: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-contrast: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-grayscale: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-hue-rotate: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-invert: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-saturate: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-sepia: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-drop-shadow: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-drop-shadow: drop-shadow(var(--drop-shadow-default1)) drop-shadow(var(--drop-shadow-default2));
  border-radius: 2px;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.sl-popover > :not(.sl-popover__tip) {
  border-radius: 2px;
  position: relative;
  z-index: 10
}

.sl-prose {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --fs-paragraph: 1em;
  --fs-paragraph-small: 0.875em;
  --fs-code: 0.875em;
  font-family: var(--font-prose);
  font-size: 16px;
  line-height: var(--lh-paragraph)
}

.sl-prose > :first-child {
  margin-top: 0
}

.sl-prose > :last-child {
  margin-bottom: 0
}

.sl-prose h1 {
  font-size: 2.25em
}

.sl-prose > h1 {
  margin-bottom: 1.11em;
  margin-top: 0
}

.sl-prose h2 {
  font-size: 1.75em;
  line-height: 1.3333333
}

.sl-prose > h2 {
  margin-bottom: 1em;
  margin-top: 1.428em
}

.sl-prose h3 {
  font-size: 1.25em
}

.sl-prose > h3 {
  margin-bottom: .8em;
  margin-top: 2em
}

.sl-prose h4 {
  font-size: 1em
}

.sl-prose > h4 {
  margin-bottom: .5em;
  margin-top: 2em
}

.sl-prose h2 + *, .sl-prose h3 + *, .sl-prose h4 + * {
  margin-top: 0
}

.sl-prose strong {
  font-weight: 600
}

.sl-prose .sl-text-lg {
  font-size: .875em
}

.sl-prose p {
  color: var(--color-text-paragraph);
  font-size: var(--fs-paragraph);
  margin-bottom: 1em;
  margin-top: 1em
}

.sl-prose p:first-child {
  margin-top: 0
}

.sl-prose p:last-child {
  margin-bottom: 0
}

.sl-prose p > a > img {
  display: inline
}

.sl-prose caption a, .sl-prose figcaption a, .sl-prose li a, .sl-prose p a, .sl-prose table a {
  color: var(--color-link)
}

.sl-prose caption a:hover, .sl-prose figcaption a:hover, .sl-prose li a:hover, .sl-prose p a:hover, .sl-prose table a:hover {
  color: var(--color-link-dark)
}

.sl-prose caption a, .sl-prose figcaption a, .sl-prose li a, .sl-prose p a, .sl-prose table a {
  --color-link: var(--color-text-primary);
  --color-link-dark: var(--color-primary-dark)
}

.sl-prose hr {
  margin-bottom: 1em;
  margin-top: 1em
}

.sl-prose .sl-live-code {
  margin: 1.25em -4px;
  table-layout: auto;
  width: 100%
}

.sl-prose .sl-live-code__inner > pre {
  margin-bottom: 0;
  margin-top: 0
}

.sl-prose .sl-callout, .sl-prose ol, .sl-prose ul {
  margin-bottom: 1.5em;
  margin-top: 1.5em
}

.sl-prose ol, .sl-prose ul {
  line-height: var(--lh-paragraph)
}

.sl-prose ol li, .sl-prose ul li {
  padding-left: 2em
}

.sl-prose ol li {
  position: relative
}

.sl-prose ol li:before {
  content: counter(list-item) ".";
  left: .75em;
  position: absolute
}

.sl-prose ul:not(.contains-task-list) li {
  position: relative
}

.sl-prose ul:not(.contains-task-list) li:before {
  background-color: var(--color-text);
  border-radius: 50%;
  content: "";
  height: .375em;
  left: .75em;
  opacity: .7;
  position: absolute;
  top: .625em;
  width: .375em
}

.sl-prose li {
  margin-bottom: 4px;
  margin-top: 4px;
  padding-left: 1.75em
}

.sl-prose li p {
  margin-bottom: .75em;
  margin-top: .75em
}

.sl-prose li > :first-child {
  margin-top: 0
}

.sl-prose > ol p + :last-child, .sl-prose > ul p + :last-child {
  margin-bottom: .75em
}

.sl-prose ol ol, .sl-prose ol ul, .sl-prose ul ol, .sl-prose ul ul {
  margin-bottom: 2px;
  margin-top: 2px
}

.sl-prose ul.contains-task-list input {
  margin-left: -1.875em;
  margin-right: .625em;
  position: relative;
  top: 1px
}

.sl-prose ul.contains-task-list p {
  margin-top: 0
}

.sl-prose figure {
  margin-bottom: 1.5em;
  margin-top: 1.5em
}

.sl-prose figure figure, .sl-prose figure img, .sl-prose figure video {
  margin-bottom: 0;
  margin-top: 0
}

.sl-prose figure > figcaption, .sl-prose figure > figcaption p {
  color: var(--color-text-muted);
  font-size: var(--fs-paragraph-small);
  line-height: var(--lh-paragraph-small);
  margin-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center
}

.sl-prose blockquote p {
  margin-bottom: .5em;
  margin-top: .5em
}

.sl-prose table {
  font-size: var(--fs-paragraph-small);
  margin-bottom: 1.5em;
  margin-left: -4px;
  margin-right: -4px;
  overflow-x: auto;
  table-layout: auto;
  width: 100%
}

.sl-prose thead td, .sl-prose thead th {
  color: var(--color-text-muted);
  font-size: .857em;
  font-weight: 500;
  padding: 8px 12px;
  text-transform: uppercase
}

.sl-prose thead td:first-child, .sl-prose thead th:first-child {
  padding-left: 4px
}

.sl-prose tbody {
  border-radius: 5px;
  box-shadow: 0 0 0 1px var(--color-border, currentColor)
}

.sl-prose tbody tr {
  border-top-width: 1px
}

.sl-prose tbody tr:first-child {
  border-top: 0
}

.sl-prose tbody tr:nth-child(2n) {
  background-color: var(--color-canvas-tint)
}

.sl-prose td {
  margin: .625em .75em;
  padding: 10px 12px;
  vertical-align: top
}

.sl-prose td:not([align=center],[align=right]), .sl-prose th:not([align=center],[align=right]) {
  text-align: left
}

.sl-prose .JsonSchemaViewer {
  border-radius: 5px;
  border-width: 1px;
  margin-left: -4px;
  margin-right: -4px
}

.sl-prose .mermaid {
  margin-bottom: 1.5em;
  margin-top: 1.5em
}

.sl-prose .mermaid > svg {
  border-radius: 5px;
  border-width: 1px;
  height: auto !important;
  padding: 1.25em
}

.sl-prose .sl-code-group .mermaid, .sl-prose .sl-code-group pre {
  margin-top: 0
}

.sl-svg-focus {
  filter: drop-shadow(0 0 1px hsla(var(--primary-h), 80%, 51%, .9))
}

.sl-radio-group__radio:hover {
  cursor: pointer
}

.sl-radio-group__radio--disabled {
  opacity: .6
}

.sl-radio-group__radio--disabled:hover {
  cursor: not-allowed
}

.sl-stack--horizontal.sl-stack--1 > :not(style) ~ :not(style) {
  margin-left: 4px
}

.sl-stack--horizontal.sl-stack--2 > :not(style) ~ :not(style) {
  margin-left: 8px
}

.sl-stack--horizontal.sl-stack--3 > :not(style) ~ :not(style) {
  margin-left: 12px
}

.sl-stack--horizontal.sl-stack--4 > :not(style) ~ :not(style) {
  margin-left: 16px
}

.sl-stack--horizontal.sl-stack--5 > :not(style) ~ :not(style) {
  margin-left: 20px
}

.sl-stack--horizontal.sl-stack--6 > :not(style) ~ :not(style) {
  margin-left: 24px
}

.sl-stack--horizontal.sl-stack--8 > :not(style) ~ :not(style) {
  margin-left: 32px
}

.sl-stack--horizontal.sl-stack--10 > :not(style) ~ :not(style) {
  margin-left: 40px
}

.sl-stack--horizontal.sl-stack--12 > :not(style) ~ :not(style) {
  margin-left: 48px
}

.sl-stack--horizontal.sl-stack--14 > :not(style) ~ :not(style) {
  margin-left: 56px
}

.sl-stack--horizontal.sl-stack--16 > :not(style) ~ :not(style) {
  margin-left: 64px
}

.sl-stack--horizontal.sl-stack--20 > :not(style) ~ :not(style) {
  margin-left: 80px
}

.sl-stack--horizontal.sl-stack--24 > :not(style) ~ :not(style) {
  margin-left: 96px
}

.sl-stack--horizontal.sl-stack--32 > :not(style) ~ :not(style) {
  margin-left: 128px
}

.sl-stack--vertical.sl-stack--1 > :not(style) ~ :not(style) {
  margin-top: 4px
}

.sl-stack--vertical.sl-stack--2 > :not(style) ~ :not(style) {
  margin-top: 8px
}

.sl-stack--vertical.sl-stack--3 > :not(style) ~ :not(style) {
  margin-top: 12px
}

.sl-stack--vertical.sl-stack--4 > :not(style) ~ :not(style) {
  margin-top: 16px
}

.sl-stack--vertical.sl-stack--5 > :not(style) ~ :not(style) {
  margin-top: 20px
}

.sl-stack--vertical.sl-stack--6 > :not(style) ~ :not(style) {
  margin-top: 24px
}

.sl-stack--vertical.sl-stack--8 > :not(style) ~ :not(style) {
  margin-top: 32px
}

.sl-stack--vertical.sl-stack--10 > :not(style) ~ :not(style) {
  margin-top: 40px
}

.sl-stack--vertical.sl-stack--12 > :not(style) ~ :not(style) {
  margin-top: 48px
}

.sl-stack--vertical.sl-stack--14 > :not(style) ~ :not(style) {
  margin-top: 56px
}

.sl-stack--vertical.sl-stack--16 > :not(style) ~ :not(style) {
  margin-top: 64px
}

.sl-stack--vertical.sl-stack--20 > :not(style) ~ :not(style) {
  margin-top: 80px
}

.sl-stack--vertical.sl-stack--24 > :not(style) ~ :not(style) {
  margin-top: 96px
}

.sl-stack--vertical.sl-stack--32 > :not(style) ~ :not(style) {
  margin-top: 128px
}

.sl-switch .sl-switch__indicator {
  transition: background-color .1s cubic-bezier(.4, 1, .75, .9)
}

.sl-switch .sl-switch__indicator .sl-switch__icon {
  visibility: hidden
}

.sl-switch .sl-switch__indicator:before {
  background-color: var(--color-canvas);
  border-radius: 50%;
  content: "";
  height: calc(100% - 4px);
  left: 0;
  margin: 2px;
  position: absolute;
  transition: left .1s cubic-bezier(.4, 1, .75, .9);
  width: calc(50% - 4px)
}

.sl-switch input:checked:disabled ~ .sl-switch__indicator {
  background-color: var(--color-primary-light)
}

.sl-switch input:checked ~ .sl-switch__indicator {
  background-color: var(--color-primary)
}

.sl-switch input:checked ~ .sl-switch__indicator .sl-switch__icon {
  visibility: visible
}

.sl-switch input:checked ~ .sl-switch__indicator:before {
  left: 50%
}

.sl-tooltip {
  --tw-blur: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-brightness: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-contrast: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-grayscale: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-hue-rotate: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-invert: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-saturate: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-sepia: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-drop-shadow: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-drop-shadow: drop-shadow(var(--drop-shadow-default1)) drop-shadow(var(--drop-shadow-default2));
  border-radius: 2px;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  font-size: 11px;
  max-width: 300px;
  padding: 4px 6px
}

.sl-tooltip > :not(.sl-tooltip_tip) {
  position: relative;
  z-index: 10
}

input {
  background-color: initial
}

.sl-focus-ring {
  --tw-ring-color: hsla(var(--primary-h), 80%, 61%, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-radius: 2px;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

:root, [data-theme=light], [data-theme=light] .sl-inverted .sl-inverted, [data-theme=light] .sl-inverted .sl-inverted .sl-inverted .sl-inverted {
  --text-h: 0;
  --text-s: 0%;
  --text-l: 15%;
  --shadow-sm: 0px 0px 1px rgba(67, 90, 111, .3);
  --shadow-md: 0px 2px 4px -2px rgba(0, 0, 0, .25), 0px 0px 1px rgba(67, 90, 111, .3);
  --shadow-lg: 0 4px 17px rgba(67, 90, 111, .2), 0 2px 3px rgba(0, 0, 0, .1), inset 0 0 0 .5px var(--color-canvas-pure), 0 0 0 .5px rgba(0, 0, 0, .2);
  --shadow-xl: 0px 0px 1px rgba(67, 90, 111, .3), 0px 8px 10px -4px rgba(67, 90, 111, .45);
  --shadow-2xl: 0px 0px 1px rgba(67, 90, 111, .3), 0px 16px 24px -8px rgba(67, 90, 111, .45);
  --drop-shadow-default1: 0 0 0.5px rgba(0, 0, 0, .6);
  --drop-shadow-default2: 0 2px 5px rgba(67, 90, 111, .3);
  --color-text-heading: hsla(var(--text-h), var(--text-s), max(3, calc(var(--text-l) - 15)), 1);
  --color-text: hsla(var(--text-h), var(--text-s), var(--text-l), 1);
  --color-text-paragraph: hsla(var(--text-h), var(--text-s), var(--text-l), 0.9);
  --color-text-muted: hsla(var(--text-h), var(--text-s), var(--text-l), 0.7);
  --color-text-light: hsla(var(--text-h), var(--text-s), var(--text-l), 0.55);
  --color-text-disabled: hsla(var(--text-h), var(--text-s), var(--text-l), 0.3);
  --canvas-h: 218;
  --canvas-s: 40%;
  --canvas-l: 100%;
  --color-canvas: hsla(var(--canvas-h), var(--canvas-s), var(--canvas-l), 1);
  --color-canvas-pure: #fff;
  --color-canvas-tint: rgba(245, 247, 250, .5);
  --color-canvas-50: #f5f7fa;
  --color-canvas-100: #ebeef5;
  --color-canvas-200: #e0e6f0;
  --color-canvas-300: #d5ddeb;
  --color-canvas-400: #cbd5e7;
  --color-canvas-500: #c0cde2;
  --color-canvas-dialog: #fff;
  --color-border-dark: hsla(var(--canvas-h), 30%, 72%, 0.5);
  --color-border: hsla(var(--canvas-h), 32%, 78%, 0.5);
  --color-border-light: hsla(var(--canvas-h), 24%, 84%, 0.5);
  --color-border-input: hsla(var(--canvas-h), 24%, 72%, 0.8);
  --color-border-button: hsla(var(--canvas-h), 24%, 20%, 0.65);
  --primary-h: 202;
  --primary-s: 100%;
  --primary-l: 55%;
  --color-text-primary: #0081cc;
  --color-primary-dark: #1891d8;
  --color-primary-darker: #126fa5;
  --color-primary: #19abff;
  --color-primary-light: #52bfff;
  --color-primary-tint: rgba(77, 190, 255, .25);
  --color-on-primary: #fff;
  --success-h: 156;
  --success-s: 95%;
  --success-l: 37%;
  --color-text-success: #05c779;
  --color-success-dark: #138b5b;
  --color-success-darker: #0f6c47;
  --color-success: #05b870;
  --color-success-light: #06db86;
  --color-success-tint: rgba(81, 251, 183, .25);
  --color-on-success: #fff;
  --warning-h: 20;
  --warning-s: 90%;
  --warning-l: 56%;
  --color-text-warning: #c2470a;
  --color-warning-dark: #d35d22;
  --color-warning-darker: #9e461a;
  --color-warning: #f46d2a;
  --color-warning-light: #f7925f;
  --color-warning-tint: rgba(246, 139, 85, .25);
  --color-on-warning: #fff;
  --danger-h: 0;
  --danger-s: 84%;
  --danger-l: 63%;
  --color-text-danger: #bc1010;
  --color-danger-dark: #d83b3b;
  --color-danger-darker: #af2323;
  --color-danger: #f05151;
  --color-danger-light: #f58e8e;
  --color-danger-tint: rgba(241, 91, 91, .25);
  --color-on-danger: #fff;
  color: var(--color-text)
}

:root .sl-inverted, [data-theme=light] .sl-inverted, [data-theme=light] .sl-inverted .sl-inverted .sl-inverted {
  --text-h: 0;
  --text-s: 0%;
  --text-l: 86%;
  --shadow-sm: 0px 0px 1px rgba(11, 13, 19, .5);
  --shadow-md: 0px 2px 4px -2px rgba(0, 0, 0, .35), 0px 0px 1px rgba(11, 13, 19, .4);
  --shadow-lg: 0 2px 14px rgba(0, 0, 0, .55), 0 0 0 0.5px hsla(0, 0%, 100%, .2);
  --shadow-xl: 0px 0px 1px rgba(11, 13, 19, .4), 0px 8px 10px -4px rgba(11, 13, 19, .55);
  --shadow-2xl: 0px 0px 1px rgba(11, 13, 19, .4), 0px 16px 24px -8px rgba(11, 13, 19, .55);
  --drop-shadow-default1: 0 0 0.5px hsla(0, 0%, 100%, .5);
  --drop-shadow-default2: 0 3px 8px rgba(0, 0, 0, .6);
  --color-text-heading: hsla(var(--text-h), var(--text-s), max(3, calc(var(--text-l) - 15)), 1);
  --color-text: hsla(var(--text-h), var(--text-s), var(--text-l), 1);
  --color-text-paragraph: hsla(var(--text-h), var(--text-s), var(--text-l), 0.9);
  --color-text-muted: hsla(var(--text-h), var(--text-s), var(--text-l), 0.7);
  --color-text-light: hsla(var(--text-h), var(--text-s), var(--text-l), 0.55);
  --color-text-disabled: hsla(var(--text-h), var(--text-s), var(--text-l), 0.3);
  --canvas-h: 218;
  --canvas-s: 32%;
  --canvas-l: 10%;
  --color-canvas: hsla(var(--canvas-h), var(--canvas-s), var(--canvas-l), 1);
  --color-canvas-pure: #0c1018;
  --color-canvas-tint: rgba(60, 76, 103, .2);
  --color-canvas-50: #3c4c67;
  --color-canvas-100: #2d394e;
  --color-canvas-200: #212a3b;
  --color-canvas-300: #19212e;
  --color-canvas-400: #171e2b;
  --color-canvas-500: #151c28;
  --color-canvas-dialog: #2d394e;
  --color-border-dark: hsla(var(--canvas-h), 24%, 23%, 0.5);
  --color-border: hsla(var(--canvas-h), 26%, 28%, 0.5);
  --color-border-light: hsla(var(--canvas-h), 19%, 34%, 0.5);
  --color-border-input: hsla(var(--canvas-h), 19%, 30%, 0.8);
  --color-border-button: hsla(var(--canvas-h), 19%, 80%, 0.65);
  --primary-h: 202;
  --primary-s: 90%;
  --primary-l: 51%;
  --color-text-primary: #66c7ff;
  --color-primary-dark: #1f83bd;
  --color-primary-darker: #186491;
  --color-primary: #12a0f3;
  --color-primary-light: #42b3f5;
  --color-primary-tint: rgba(85, 187, 246, .25);
  --color-on-primary: #fff;
  --success-h: 156;
  --success-s: 95%;
  --success-l: 67%;
  --color-text-success: #41f1ab;
  --color-success-dark: #47dca0;
  --color-success-darker: #24bc7f;
  --color-success: #62f3b9;
  --color-success-light: #a0f8d5;
  --color-success-tint: rgba(89, 243, 181, .25);
  --color-on-success: #fff;
  --warning-h: 20;
  --warning-s: 90%;
  --warning-l: 50%;
  --color-text-warning: #ec7d46;
  --color-warning-dark: #b55626;
  --color-warning-darker: #8b421d;
  --color-warning: #e75d18;
  --color-warning-light: #ec7d46;
  --color-warning-tint: rgba(238, 142, 93, .25);
  --color-on-warning: #fff;
  --danger-h: 0;
  --danger-s: 84%;
  --danger-l: 43%;
  --color-text-danger: #e74b4b;
  --color-danger-dark: #972626;
  --color-danger-darker: #721d1d;
  --color-danger: #c11a1a;
  --color-danger-light: #e22828;
  --color-danger-tint: rgba(234, 98, 98, .25);
  --color-on-danger: #fff;
  color: var(--color-text)
}

[data-theme=dark], [data-theme=dark] .sl-inverted .sl-inverted, [data-theme=dark] .sl-inverted .sl-inverted .sl-inverted .sl-inverted {
  --text-h: 0;
  --text-s: 0%;
  --text-l: 85%;
  --shadow-sm: 0px 0px 1px rgba(11, 13, 19, .5);
  --shadow-md: 0px 2px 4px -2px rgba(0, 0, 0, .35), 0px 0px 1px rgba(11, 13, 19, .4);
  --shadow-lg: 0 2px 14px rgba(0, 0, 0, .55), 0 0 0 0.5px hsla(0, 0%, 100%, .2);
  --shadow-xl: 0px 0px 1px rgba(11, 13, 19, .4), 0px 8px 10px -4px rgba(11, 13, 19, .55);
  --shadow-2xl: 0px 0px 1px rgba(11, 13, 19, .4), 0px 16px 24px -8px rgba(11, 13, 19, .55);
  --drop-shadow-default1: 0 0 0.5px hsla(0, 0%, 100%, .5);
  --drop-shadow-default2: 0 3px 8px rgba(0, 0, 0, .6);
  --color-text-heading: hsla(var(--text-h), var(--text-s), max(3, calc(var(--text-l) - 15)), 1);
  --color-text: hsla(var(--text-h), var(--text-s), var(--text-l), 1);
  --color-text-paragraph: hsla(var(--text-h), var(--text-s), var(--text-l), 0.9);
  --color-text-muted: hsla(var(--text-h), var(--text-s), var(--text-l), 0.7);
  --color-text-light: hsla(var(--text-h), var(--text-s), var(--text-l), 0.55);
  --color-text-disabled: hsla(var(--text-h), var(--text-s), var(--text-l), 0.3);
  --canvas-h: 218;
  --canvas-s: 32%;
  --canvas-l: 8%;
  --color-canvas: hsla(var(--canvas-h), var(--canvas-s), var(--canvas-l), 1);
  --color-canvas-pure: #090c11;
  --color-canvas-tint: rgba(57, 71, 96, .2);
  --color-canvas-50: #262f40;
  --color-canvas-100: #1a212d;
  --color-canvas-200: #121821;
  --color-canvas-300: #0e131a;
  --color-canvas-400: #0c1017;
  --color-canvas-500: #0c1017;
  --color-canvas-dialog: #1a212d;
  --color-border-dark: hsla(var(--canvas-h), 24%, 21%, 0.5);
  --color-border: hsla(var(--canvas-h), 26%, 26%, 0.5);
  --color-border-light: hsla(var(--canvas-h), 19%, 32%, 0.5);
  --color-border-input: hsla(var(--canvas-h), 19%, 28%, 0.8);
  --color-border-button: hsla(var(--canvas-h), 19%, 80%, 0.65);
  --primary-h: 202;
  --primary-s: 80%;
  --primary-l: 36%;
  --color-text-primary: #66c7ff;
  --color-primary-dark: #1c5a7d;
  --color-primary-darker: #154560;
  --color-primary: #126fa5;
  --color-primary-light: #1685c5;
  --color-primary-tint: rgba(21, 130, 193, .25);
  --color-on-primary: #fff;
  --success-h: 156;
  --success-s: 95%;
  --success-l: 37%;
  --color-text-success: #4be7a9;
  --color-success-dark: #145239;
  --color-success-darker: #10422e;
  --color-success: #0f6c47;
  --color-success-light: #128255;
  --color-success-tint: rgba(26, 188, 123, .25);
  --color-on-success: #fff;
  --warning-h: 20;
  --warning-s: 90%;
  --warning-l: 56%;
  --color-text-warning: #e28150;
  --color-warning-dark: #7d4021;
  --color-warning-darker: #61311a;
  --color-warning: #9e461a;
  --color-warning-light: #c1551f;
  --color-warning-tint: rgba(184, 81, 30, .25);
  --color-on-warning: #fff;
  --danger-h: 0;
  --danger-s: 84%;
  --danger-l: 63%;
  --color-text-danger: #d55;
  --color-danger-dark: #892929;
  --color-danger-darker: #6a2020;
  --color-danger: #af2323;
  --color-danger-light: #d12929;
  --color-danger-tint: rgba(179, 35, 35, .25);
  --color-on-danger: #fff;
  color: var(--color-text)
}

[data-theme=dark] .sl-inverted, [data-theme=dark] .sl-inverted .sl-inverted .sl-inverted {
  --text-h: 0;
  --text-s: 0%;
  --text-l: 89%;
  --shadow-sm: 0px 0px 1px rgba(11, 13, 19, .5);
  --shadow-md: 0px 2px 4px -2px rgba(0, 0, 0, .35), 0px 0px 1px rgba(11, 13, 19, .4);
  --shadow-lg: 0 2px 14px rgba(0, 0, 0, .55), 0 0 0 0.5px hsla(0, 0%, 100%, .2);
  --shadow-xl: 0px 0px 1px rgba(11, 13, 19, .4), 0px 8px 10px -4px rgba(11, 13, 19, .55);
  --shadow-2xl: 0px 0px 1px rgba(11, 13, 19, .4), 0px 16px 24px -8px rgba(11, 13, 19, .55);
  --drop-shadow-default1: 0 0 0.5px hsla(0, 0%, 100%, .5);
  --drop-shadow-default2: 0 3px 8px rgba(0, 0, 0, .6);
  --color-text-heading: hsla(var(--text-h), var(--text-s), max(3, calc(var(--text-l) - 15)), 1);
  --color-text: hsla(var(--text-h), var(--text-s), var(--text-l), 1);
  --color-text-paragraph: hsla(var(--text-h), var(--text-s), var(--text-l), 0.9);
  --color-text-muted: hsla(var(--text-h), var(--text-s), var(--text-l), 0.7);
  --color-text-light: hsla(var(--text-h), var(--text-s), var(--text-l), 0.55);
  --color-text-disabled: hsla(var(--text-h), var(--text-s), var(--text-l), 0.3);
  --canvas-h: 218;
  --canvas-s: 32%;
  --canvas-l: 13%;
  --color-canvas: hsla(var(--canvas-h), var(--canvas-s), var(--canvas-l), 1);
  --color-canvas-pure: #111722;
  --color-canvas-tint: rgba(66, 83, 112, .2);
  --color-canvas-50: #2b374a;
  --color-canvas-100: #222b3a;
  --color-canvas-200: #1a212e;
  --color-canvas-300: #141a24;
  --color-canvas-400: #121721;
  --color-canvas-500: #121721;
  --color-canvas-dialog: #222b3a;
  --color-border-dark: hsla(var(--canvas-h), 24%, 26%, 0.5);
  --color-border: hsla(var(--canvas-h), 26%, 31%, 0.5);
  --color-border-light: hsla(var(--canvas-h), 19%, 37%, 0.5);
  --color-border-input: hsla(var(--canvas-h), 19%, 33%, 0.8);
  --color-border-button: hsla(var(--canvas-h), 19%, 80%, 0.65);
  --primary-h: 202;
  --primary-s: 80%;
  --primary-l: 33%;
  --color-text-primary: #66c7ff;
  --color-primary-dark: #1a5475;
  --color-primary-darker: #14425c;
  --color-primary: #116697;
  --color-primary-light: #147cb8;
  --color-primary-tint: rgba(21, 130, 193, .25);
  --color-on-primary: #fff;
  --success-h: 156;
  --success-s: 95%;
  --success-l: 67%;
  --color-text-success: #4be7a9;
  --color-success-dark: #25986a;
  --color-success-darker: #1c7350;
  --color-success: #1bc581;
  --color-success-light: #28e297;
  --color-success-tint: rgba(26, 188, 123, .25);
  --color-on-success: #fff;
  --warning-h: 20;
  --warning-s: 90%;
  --warning-l: 50%;
  --color-text-warning: #e28150;
  --color-warning-dark: #713a1e;
  --color-warning-darker: #552b16;
  --color-warning: #914018;
  --color-warning-light: #ab4c1c;
  --color-warning-tint: rgba(184, 81, 30, .25);
  --color-on-warning: #fff;
  --danger-h: 0;
  --danger-s: 84%;
  --danger-l: 43%;
  --color-text-danger: #d55;
  --color-danger-dark: #5e1c1c;
  --color-danger-darker: #471515;
  --color-danger: #771818;
  --color-danger-light: #911d1d;
  --color-danger-tint: rgba(179, 35, 35, .25);
  --color-on-danger: #fff;
  color: var(--color-text)
}

.sl-elements {
  font-size: 13px
}

.sl-elements .svg-inline--fa {
  display: inline-block
}

.sl-elements .DocsSkeleton {
  animation: skeleton-glow .5s linear infinite alternate;
  background: rgba(206, 217, 224, .2);
  background-clip: padding-box !important;
  border-color: rgba(206, 217, 224, .2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  user-select: none
}

.sl-elements .Model {
  --fs-code: 12px
}

.sl-elements .ElementsTableOfContentsItem:hover {
  color: inherit;
  text-decoration: none
}

.sl-elements .ParameterGrid {
  align-items: center;
  display: grid;
  grid-template-columns:fit-content(120px) 20px auto;
  margin-bottom: 16px;
  padding-bottom: 0;
  row-gap: 3px
}

.sl-elements .TryItPanel > :nth-child(2) {
  overflow: auto
}

.sl-elements .OperationParametersContent {
  max-height: 162px
}

.sl-elements .TextRequestBody {
  margin-bottom: 16px;
  max-height: 200px;
  overflow-y: auto;
  padding-bottom: 0
}

.sl-elements .HttpOperation .JsonSchemaViewer .sl-markdown-viewer p, .sl-elements .HttpOperation__Parameters .sl-markdown-viewer p, .sl-elements .Model .JsonSchemaViewer .sl-markdown-viewer p {
  font-size: 12px;
  line-height: 1.5em
}

.sl-elements .sl-markdown-viewer .JsonSchemaViewer {
  padding-right: 10px
}
